import loadable from "@loadable/component";

import underlineGraphic from "./../assets/icons/header-orange-underline.svg";
import playStoreImage from "./../assets/images/google-play.webp";
import rigthArrowBlue from "./../assets/icons/blue-right-arrow.svg";
import { EXPLORE_JOB_APP } from "./../shared/constants/routing-constants";
import {
  useFreshersJobsHome,
  useGovtJobsHome,
  useJobCategoriesHome,
  useLatestJobsHome,
} from "./../network/APIs/categories";
import { useNavigate } from "react-router";
import { createSearchParams, Link } from "react-router-dom";
//images
import jobLogo from "../assets/images/jobs_icon.png";
import studentLogo from "../assets/images/students_icon.png";
import gesia from "../assets/images/Gesia.png";
import iitkharagpur from "../assets/images/iitkharagpur3 (1).png";
import collegeprofileplaceholder from "../assets/images/college_profile_placeholder2.png";

const Category = loadable(() => import("./../shared-components"), {
  resolveComponent: (components) => components.Category,
});

const GovJobs = loadable(() => import("./../shared-components"), {
  resolveComponent: (components) => components.GovJobs,
});

const LatestJobs = loadable(() => import("./../shared-components"), {
  resolveComponent: (components) => components.LatestJobs,
});

const SearchBar = loadable(() => import("./../shared-components"), {
  resolveComponent: (components) => components.SearchBar,
});

function HomePage() {
  const jobsCategories = useJobCategoriesHome();
  const govtJobs = useGovtJobsHome();
  const feshersJobs = useFreshersJobsHome();
  const latestJobs = useLatestJobsHome();

  const navigate = useNavigate();

  const searchHandler = (search: string) => {
    if (search && search.trim()) {
      navigate({
        pathname: `${EXPLORE_JOB_APP}`,
        search: `?${createSearchParams({
          search: search.trim(),
        })}`,
      });
    }
  };

  return (
    <>
      {/* Header Starts */}
      <div
        style={{
          backgroundPosition: "100% 42%",
        }}
        className="px-[124px] pt-[88px] pb-[40px] bg-[#F8F8FD] bg-no-repeat sm:bg-none sm:px-[40px] bg-homepage"
      >
        <h1 className="text-[72px] sm:text-[50px] font-[700] leading-[79px] sm:leading-[59px] max-w-[360px] mb-[30px] font-DmSans">
          Mission to Boost{" "}
          <span className="text-[#FF5F14] font-DmSans">Employment</span>
        </h1>
        <img
          loading="lazy"
          src={underlineGraphic}
          alt="bg-graphic"
          className="mb-[20px] w-[400px] sm:hidden"
        />
        <p className="text-[20px] sm:text-[16px] text-[#515B6F] max-w-[540px] mb-[45px]">
          Freshment is India's leading Job Portal - Search & Apply for Latest
          Job Vacancies across Top Companies in India, Employers can Post Job &
          Hire Candidates for Free
        </p>
        <div className="flex sm:flex-col-reverse">
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.govtjobs.naukari"
            }
            target="_blank"
          >
            <img
              loading="lazy"
              src={playStoreImage}
              alt="google play"
              height={70}
              className="mr-[30px] h-[70px] sm:h-[40px] cursor-pointer"
            />
          </Link>
          <p className="text-[20px] text-[#515B6F] max-w-[370px] mb-[45px]">
            Join Over
            <span className="text-[#FF5F14] font-bold"> 1 Lakh+</span> Freshers
            Finding Their Dream Careers on Freshment!
          </p>
        </div>
        <div className="max-w-[852px]">
          <SearchBar
            handleSearch={searchHandler}
            placeholder="Job title or keyword"
            subtitle="Popular : AI/ML, Finance, Mechanical, Electrical, Civil, Marketing"
          />
        </div>
      </div>
      {/* Header Ends */}

      <div className="px-[124px] sm:px-[40px]">
        <div className="mt-[80px] sm:mt-[60px]">
          <div className="text-[48px] sm:text-[30px] font-[700] mb-[48px] flex justify-between items-center">
            <p className="text-[#25324B] font-DmSans">
              Explore by{" "}
              <span className="text-[#FF5F14] font-DmSans"> category</span>
            </p>
            <Link
              to="/explore-job"
              className="text-[#2670FF] text-[16px] flex gap-[16px] sm:gap-[6px]"
            >
              Show all jobs{" "}
              <img
                loading="lazy"
                alt="right arrow"
                src={rigthArrowBlue}
                height="24"
                width="24"
              />
            </Link>
          </div>
          <div className="grid grid-cols-4 gap-[32px] xsm:grid-cols-1 sm:grid-cols-2  sm:gap-[20px]">
            {jobsCategories.data?.map((category) => {
              return <Category {...category} key={category.id} />;
            })}
          </div>
        </div>

        <div className="w-full mt-[80px] mb-[72px]">
          <footer className="footer">
            <div className="footer-container">
              <div className="footer-row">
                <div className="left-footer">
                  <p className="left-footer-title">
                    Featured In GESIA, IIT-Kharagpur Top 5 Emerging Start-ups
                  </p>
                  <div className="left-footer-content">
                    <div className="left-footer-images">
                      <img
                        className="footer-image img-left"
                        src={iitkharagpur}
                        alt="IIT Kharagpur Logo"
                      />
                      <img
                        className="footer-image img-left-small"
                        src={gesia}
                        alt="GESIA Logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="right-footer">
                  <p className="right-footer-title">
                    Trusted by 2500+ Colleges & 50+ Universities
                  </p>
                  <div className="right-footer-content">
                    <div className="right-footer-item">
                      <img
                        className="footer-image"
                        src={studentLogo}
                        alt="Students Icon"
                      />
                      <div className="footer-text">
                        <p className="footer-bold">1 Lakh+</p>
                        <p className="footer-subtext">Users</p>
                      </div>
                    </div>
                    <div className="right-footer-item">
                      <img
                        className="footer-image"
                        src={jobLogo}
                        alt="Jobs Icon"
                      />
                      <div className="footer-text">
                        <p className="footer-bold">5000+</p>
                        <p className="footer-subtext">Jobs</p>
                      </div>
                    </div>
                    <div className="right-footer-item">
                      <img
                        className="footer-image img-large"
                        src={collegeprofileplaceholder}
                        alt="Colleges Icon"
                      />
                      <div className="footer-text">
                        <p className="footer-bold">2500+</p>
                        <p className="footer-subtext">Colleges</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>

        <div className="mt-[80px] mb-[72px]">
          <div className="text-[48px] sm:text-[30px] font-[700] mb-[48px] flex justify-between items-center">
            <p className="text-[#25324B] font-DmSans">
              Trending <span className="text-[#FF5F14] font-DmSans"> jobs</span>
            </p>
            <Link
              to="/freshers-job"
              className="text-[#2670FF] text-[16px] flex gap-[16px] sm:gap-[6px]"
            >
              Show all jobs{" "}
              <img
                loading="lazy"
                alt="right arrow"
                src={rigthArrowBlue}
                height="24"
                width="24"
              />
            </Link>
          </div>

          <div className="grid grid-cols-4 gap-[32px] xsm:grid-cols-1 sm:grid-cols-2 sm  sm:gap-[20px]">
            {feshersJobs.data?.map((job) => {
              return <GovJobs {...job} key={job.id} />;
            })}
          </div>
        </div>

        <div className="mt-[80px] mb-[72px] sm:hidden">
          <div className="text-[48px] sm:text-[30px] font-[700] mb-[48px] flex justify-between items-center">
            <p className="text-[#25324B] font-DmSans">
              Public Sector
              <span className="text-[#FF5F14] font-DmSans"> jobs</span>
            </p>
            <Link
              to="/government-job"
              className="text-[#2670FF] text-[16px] flex gap-[16px] sm:gap-[6px]"
            >
              Show all jobs{" "}
              <img
                loading="lazy"
                alt="right arrow"
                src={rigthArrowBlue}
                height="24"
                width="24"
              />
            </Link>
          </div>

          <div className="grid grid-cols-4 gap-[32px] sm:grid-cols-1 sm:gap-[20px]">
            {govtJobs.data?.map((job) => {
              return <GovJobs {...job} key={job.id} />;
            })}
          </div>
        </div>
      </div>

      {/* Latest Jobs */}
      <div
        className="px-[124px] sm:px-[40px] pt-[72px] pb-[60px]"
        style={{
          backgroundColor: "#F8F8FD",
        }}
      >
        <div className="text-[48px] sm:text-[30px] font-[700] mb-[48px] flex justify-between items-center">
          <p className="text-[#25324B] font-DmSans">
            Latest{" "}
            <span className="text-[#FF5F14] font-DmSans"> jobs open</span>
          </p>
          <Link
            to="/freshers-job"
            className="text-[#2670FF] text-[16px] flex gap-[16px] sm:gap-[6px]"
          >
            Show all jobs{" "}
            <img
              loading="lazy"
              alt="right arrow"
              src={rigthArrowBlue}
              height="24"
              width="24"
            />
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-x-[32px] gap-y-[16px] sm:grid-cols-1 sm:gap-y-[14px]">
          {latestJobs.data?.map((job) => {
            return <LatestJobs {...job} key={job.id} />;
          })}
        </div>
      </div>
    </>
  );
}

export default HomePage;
