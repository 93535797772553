import { useQuery } from '@tanstack/react-query';
import { getData } from '../api-client';
import { CURRENT_AFFAIRS, CURRENT_AFFAIRS_DETAILS, FEATURED_CURRENT_AFFAIRS, FEED } from '../../shared/constants/routing-constants';


export function useCurrentAffairs(queryParam?: { [key: string]: any }) {
  return useQuery({ queryKey: ['currentAffairs', queryParam], queryFn: () => getData(`${FEED}/${CURRENT_AFFAIRS}`, { languageType: 1, offset: 0, limit: 10, ...queryParam }).then(res => res) })
}

export function useCurrentAffairDetails(post_id: number | string) {
  return useQuery({ queryKey: ['currentAffairDetails', post_id], queryFn: () => getData(`${FEED}/${CURRENT_AFFAIRS_DETAILS}`, { languageType: 1, post_id }).then(res => res.data) })
}

export function useFeaturedCurrentAffairs() {
  return useQuery({ queryKey: ['featuredCurrentAffairs'], queryFn: () => getData(`${FEED}/${FEATURED_CURRENT_AFFAIRS}`, { languageType: 1, offset: 0 }).then(res => res.data) })
}