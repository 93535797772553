import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { postCampusConnect } from "../../network/APIs/campus";
import campusBg from "./../../assets/images/campusBg.webp";
import user from "./../../assets/icons/user.svg";
import contact from "./../../assets/icons/contact.svg";
import email from "./../../assets/icons/email.svg";
import building from "./../../assets/icons/building.svg";
import bi_people from "./../../assets/icons/bi_people.svg";
import { getTestimonals } from "../../network/APIs/materials";
//images
import jobLogo from "./../../assets/images/jobs_icon.png";
import studentLogo from "./../../assets/images/students_icon.png";
import gesia from "./../../assets/images/Gesia.png";
import iitkharagpur from "./../../assets/images/iitkharagpur3 (1).png";
import collegeprofileplaceholder from "./../../assets/images/college_profile_placeholder2.png";
import educonnectpro from "./../../assets/images/freshmentLogo2.png";
import student from "./../../assets/images/student.png";
import campus from "./../../assets/images/campus.png";
import { Rating } from "@mui/material";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

interface Config {
  total_students_onboarded: string;
  total_colleges_onboarded: string;
  trial_text: string;
  reviews_header_text: string;
  // Add other properties of `config` if needed
}

const Campus = () => {
  const [campusSubmitBtn, setCampusSubmitBtn] = useState("Submit");
  const [isLoading, setIsLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [config, setConfig] = useState<Config>({
    total_students_onboarded: "",
    total_colleges_onboarded: "",
    trial_text: "",
    reviews_header_text: "",
  });
  const campusConnectFormRef = React.useRef<any>();
  const swiperRef = useRef(null);
  useEffect(() => {
    getTestimonals().then((response) => {
      console.log(response?.config);
      setConfig(
        response?.config || {
          total_students_onboarded: "",
          total_colleges_onboarded: "",
          trial_text: "",
          reviews_header_text: "",
        }
      );
      setTestimonials(response?.data);
    });
  }, []);
  const campusConnectFormHandler = (e) => {
    e.preventDefault();

    const prepareData = {
      name: campusConnectFormRef.current.name.value || "",
      email: campusConnectFormRef.current.email.value || "",
      contact_no: campusConnectFormRef.current.contact.value || "",
      institute_name: campusConnectFormRef.current.institute.value || "",
      no_of_students: campusConnectFormRef.current.totalStudents.value || "",
    };

    if (!isLoading) {
      setIsLoading(true);
      postCampusConnect(prepareData)
        .then((response) => {
          setIsLoading(false);
          if (response.success) {
            campusConnectFormRef.current.reset();
            setCampusSubmitBtn("Response submitted!");
            setTimeout(() => {
              setCampusSubmitBtn("Submit");
            }, 2000);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url(${campusBg})`,
            height: "1000px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="pt-4 sm:pt-6 sm:ml-10 ml-32 w-full sm:w-auto max-w-[490px] mx-auto">
            <div>
              <img
                src={educonnectpro}
                alt="educonnectpro"
                className="w-full sm:w-96"
              />
            </div>
            <div className="flex items-center gap-6 py-8">
              <div className="flex items-center gap-4 my-3">
                <img src={student} className="w-16 h-16" alt="abcd" />
                <div>
                  <p className="font-bold mb-0 pb-0">
                    {config?.total_students_onboarded}
                  </p>
                  <p className="text-xs text-[#707070]">
                    Student Onboarded on EduConnectPro
                  </p>
                </div>
              </div>
              <div className="flex justify-end items-center gap-6">
                <img src={campus} className="w-16 h-16" alt="abcd" />
                <div>
                  <p className="font-bold mb-0 pb-0">
                    {" "}
                    {config?.total_colleges_onboarded}
                  </p>
                  <p className="text-xs text-[#707070]">
                    Colleges Joined EduConnectPro
                  </p>
                </div>
              </div>
            </div>
            <p className="text-4xl sm:text-5xl mb-6 font-semibold text-[#25324B] font-DmSans">
              Join EduConnectPro
            </p>
            <p className="text-lg sm:text-xl font-medium text-[#707070]">
              {config?.trial_text}
            </p>
            <form
              ref={campusConnectFormRef}
              onSubmit={campusConnectFormHandler}
            >
              <div className="grid grid-rows-6 grid-cols-1 gap-[24px] mt-[36px] sm:w-[calc(100vw-80px)]">
                <div className="relative">
                  <img
                    loading="lazy"
                    src={user}
                    alt="user"
                    height={24}
                    width={24}
                    className="absolute top-5 left-5"
                  />
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    minLength={3}
                    className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                    placeholder="Full Name"
                    autoComplete="off"
                  />
                </div>
                <div className="relative">
                  <img
                    loading="lazy"
                    src={contact}
                    alt="user"
                    height={24}
                    width={24}
                    className="absolute top-5 left-5"
                  />
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    minLength={10}
                    maxLength={10}
                    onChange={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/gi, ""))
                    }
                    className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                    placeholder="Contact Number"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="relative">
                  <img
                    loading="lazy"
                    src={email}
                    alt="user"
                    height={24}
                    width={24}
                    className="absolute top-5 left-5"
                  />
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    minLength={3}
                    className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                    placeholder="Email Address"
                    autoComplete="off"
                  />
                </div>
                <div className="relative">
                  <img
                    loading="lazy"
                    src={building}
                    alt="user"
                    height={24}
                    width={24}
                    className="absolute top-5 left-5"
                  />
                  <input
                    id="institute"
                    name="institute"
                    type="text"
                    minLength={3}
                    required
                    className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                    placeholder="Name of Institute"
                    autoComplete="off"
                  />
                </div>
                <div className="relative">
                  <img
                    loading="lazy"
                    src={bi_people}
                    alt="user"
                    height={24}
                    width={24}
                    className="absolute top-5 left-5"
                  />
                  <input
                    id="totalStudents"
                    name="totalStudents"
                    type="number"
                    required
                    className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                    placeholder="Number of Students"
                    autoComplete="off"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-[#2670FF] h-[57px] text-[18px] font-[700] rounded-[10px] text-white shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                >
                  {campusSubmitBtn}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>{" "}
      <div className="w-full mt-[80px] mb-[72px]">
        <footer className="footer">
          <div className="footer-container">
            <div className="footer-row">
              <div className="left-footer">
                <p className="left-footer-title">
                  Featured In GESIA, IIT-Kharagpur Top 5 Emerging Start-ups
                </p>
                <div className="left-footer-content-new">
                  <div className="left-footer-images">
                    <img
                      className="footer-image img-left"
                      src={iitkharagpur}
                      alt="IIT Kharagpur Logo"
                    />
                    <img
                      className="footer-image img-left-small"
                      src={gesia}
                      alt="GESIA Logo"
                    />
                  </div>
                </div>
              </div>
              <div className="right-footer">
                <p className="right-footer-title">
                  Trusted by 2500+ Colleges & 50+ Universities
                </p>
                <div className="right-footer-content">
                  <div className="right-footer-item">
                    <img
                      className="footer-image"
                      src={studentLogo}
                      alt="Students Icon"
                    />
                    <div className="footer-text">
                      <p className="footer-bold">1 Lakh+</p>
                      <p className="footer-subtext">Users</p>
                    </div>
                  </div>
                  <div className="right-footer-item">
                    <img
                      className="footer-image"
                      src={jobLogo}
                      alt="Jobs Icon"
                    />
                    <div className="footer-text">
                      <p className="footer-bold">5000+</p>
                      <p className="footer-subtext">Jobs</p>
                    </div>
                  </div>
                  <div className="right-footer-item">
                    <img
                      className="footer-image img-large"
                      src={collegeprofileplaceholder}
                      alt="Colleges Icon"
                    />
                    <div className="footer-text">
                      <p className="footer-bold">2500+</p>
                      <p className="footer-subtext">Colleges</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <section>
        <h3 className="text-center text-wrap my-6 text-[#25324B] text-[22px] font-[600] font-DmSans">
          {config?.reviews_header_text}
        </h3>

        <div className="px-2 max-w-[1680px] flex w-full items-center mx-auto my-0">
          {/* Custom Navigation Buttons */}
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            className="mx-6  block sm:hidden   self-center h-fit p-2 bg-[#fff] rounded-full shadow-md shadow-blue-200 z-10 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                <path
                  fill="currentColor"
                  d="M7.94 13.06a1.5 1.5 0 0 1 0-2.12l5.656-5.658a1.5 1.5 0 1 1 2.121 2.122L11.122 12l4.596 4.596a1.5 1.5 0 1 1-2.12 2.122l-5.66-5.658Z"
                ></path>
              </g>
            </svg>
          </button>

          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              980: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper !px-2.5 !pt-0 !pb-10 max-w-[1350px] mx-auto swiper-container swiper-container-initialized swiper-container-horizontal"
          >
            {testimonials &&
              testimonials.length > 0 &&
              testimonials.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="rounded-lg border border-gray-200 shadow-md hover:shadow-lg bg-gradient-to-b from-white to-gray-50 text-center !flex !flex-col items-center !h-[500px] p-5 transition-transform duration-300 hover:scale-105"
                >
                  {/* Profile Image */}
                  <div className="mt-[10px] mb-[20px] w-fit self-center p-4 rounded-full border-2 border-[#D6DDEB]">
                    <img
                      src={item?.profile_picture ?? collegeprofileplaceholder}
                      className="w-[85px] h-auto object-cover"
                    />
                  </div>

                  {/* Rating */}
                  <Rating
                    name="read-only"
                    value={item?.rating ?? 0}
                    readOnly
                    className="mb-4"
                  />

                  {/* Review Text */}
                  <p className="text-gray-600 text-sm mb-6 leading-relaxed italic h-[170px] overflow-y-auto">
                    "{item?.testimonial_text ?? "-"}"
                  </p>

                  {/* Placement Office Name */}
                  <h4 className="text-lg mb-2 font-semibold text-gray-800">
                    {item?.reviewer_name ?? "Anonymous"}
                  </h4>

                  {/* College Name */}
                  <p className="text-sm text-gray-500">
                    {item?.college_name ?? "XYZ College"}
                  </p>
                </SwiperSlide>
              ))}
          </Swiper>

          <button
            onClick={() => swiperRef.current?.slideNext()}
            className="mx-2 block sm:hidden self-center h-fit p-2 bg-[#fff] rounded-full shadow-md shadow-blue-200 z-10 
               "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                <path
                  fill="currentColor"
                  d="M16.06 10.94a1.5 1.5 0 0 1 0 2.12l-5.656 5.658a1.5 1.5 0 1 1-2.121-2.122L12.879 12L8.283 7.404a1.5 1.5 0 0 1 2.12-2.122l5.658 5.657Z"
                ></path>
              </g>
            </svg>
          </button>
        </div>
      </section>
    </>
  );
};

function CampusConnect() {
  return (
    <>
      <Helmet>
        <title>Campus Connect</title>
        <meta name="description" content="Campus Connect" />
      </Helmet>
      <Campus />
    </>
  );
}

export default CampusConnect;
