import { getData, postData } from '../api-client';
import { COMPANY, FEED, GET_USER_LOCATIONS, POST_YOUR_JOB } from '../../shared/constants/routing-constants';


export function postYourJob(jsonData: any) {
  const formData = new FormData();

  for (const key in jsonData) {
    if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
      const element = jsonData[key];
      formData.append(key, element)
    }
  }

  return postData(`${COMPANY}/${POST_YOUR_JOB}`, formData);
}


export function getCities(): Promise<any> {
  return getData(`${COMPANY}/${GET_USER_LOCATIONS}`);
}