import React, { useEffect, useState } from "react";
import {
  DATE_POSTED_FILTERS,
  exploreJobTabs,
  govJobs,
  QUALIFICATION_FILTERS,
  studyMaterialMenu,
} from "./../constants/constants";
import {
  AllJobs,
  EmptyState,
  JobFilter,
  MenuItem,
  MobileFilters,
  PaginationComponent,
  SearchBar,
} from "../../shared-components";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import filter from "../../assets/icons/filter.svg";
import { IFiltersGroup } from "../../shared-components/interfaces/current-affairs";
import { IHandlePageChangeEvent } from "../../shared-components/interfaces/common.interface";
import { getExploreJobsCategories } from "../../network/APIs/categories";
import { IJobCategories } from "../../shared-components/interfaces/job-categories.interface";
import exploreJobsUnderline from "../../assets/icons/explore-jobs-underline.svg";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { deepCopyJSON } from "../../shared-components/constants/constants";
import { useExploreJobs } from "../../network/APIs/jobs";

function ExploreJob() {
  const ITEMS_PER_PAGE = 5;
  const [selectedTab, setSelectedTab] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const date_posted = searchParams.get("date_posted");
  const qualification = searchParams.get("qualification");

  const [isFiltersLoaded, setFiltersLoaded] = useState(false);

  const [filters, setFilters] = useState<IFiltersGroup[]>([
    deepCopyJSON(DATE_POSTED_FILTERS),
  ]);

  const [query, setQuery] = useState<any>();
  const { data: filteredItems } = useExploreJobs(query);


  // Fetch current affairs, job categories
  useEffect(() => {
    getExploreJobsCategories()
      .then((result) => {
        if (result.success) {
          const newCategory: IFiltersGroup = {
            label: "Category",
            type: "checkbox",
            value: "categoryId",
            filters: [],
          };
          result.data?.categories.forEach((category: IJobCategories) => {
            newCategory.filters.push({
              id: Number(category.id),
              lable: category.category_name,
              value: category.id,
            });
          });
          setFilters((prev) => [newCategory, ...prev]);
        } else {
          setFilters((prev) => [...prev]);
        }
        setFiltersLoaded(true);
      })
      .catch(() => {
        setFiltersLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (isFiltersLoaded) {
      const params = {
        categoryId,
        // date_posted,
        // qualification,
      };

      const f = [...filters];
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const value = params[key];
          const cat = (value || "").split(",");

          f.forEach((filter) => {
            if (filter.value === key) {
              filter.filters.forEach((ff) => {
                if (cat.includes(ff.value + "")) {
                  ff.isChecked = true;
                }
              });
            }
          });
        }
      }
      setFilters(f);
    }
  }, [isFiltersLoaded]);

  // On category query param change
  useEffect(() => {
    const newQuery = {
      categoryId: Array.isArray(categoryId) ? categoryId.join(",") : categoryId,
      search,
      date_posted,
      qualification,
      offset:
        ((isNaN(Number(page)) || !Number(page) ? 1 : Number(page)) - 1) *
        ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    };
    setQuery(newQuery);
  }, [categoryId, page, search, date_posted, qualification]);

  // Handle filter
  const handleFilterChange = (appliedFilters: IFiltersGroup[]) => {
    const queryParams: { [key: string]: any } = {};

    appliedFilters.forEach((filter_group) => {
      const groupValues: any = [];

      filter_group.filters.forEach((filter) => {
        if (filter.isChecked) {
          groupValues.push(filter.value);
        }
      });

      if (groupValues?.length) {
        queryParams[filter_group.value] =
          groupValues.length === 1 ? groupValues[0] : groupValues;
      }
    });

    searchParams.delete("categoryId");
    searchParams.delete(DATE_POSTED_FILTERS.value);
    searchParams.delete(QUALIFICATION_FILTERS.value);
    Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, queryParams[key]);
    });
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const handleSearch = (search: string) => {
    searchParams.delete("page");
    if (search && search.trim().length) {
      searchParams.set("search", search.trim());
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = ({ page }: IHandlePageChangeEvent) => {
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  const onTabSelect = (tabId: number, tabValue: string) => {
    setSelectedTab(tabId);
    searchParams.set("qualification", tabValue);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Helmet>
        <title>
          Explore latest job openings in Mumbai, Pune, Bangalore, Delhi,
          Ahmedabad on Freshment
        </title>

        <meta
          name="title"
          content="Explore latest job openings in Mumbai, Pune, Bangalore, Delhi, Ahmedabad on Freshment"
        />

        <meta
          name="description"
          content="Apply for 5000 Jobs in India, Explore latest job openings in Rajkot, Ahmedabad, Mumbai, Pune, Bangalore, Delhi on Freshment - India’s No.1 Job Portal | Summer Internship | Remote Jobs | Freshers IT Jobs"
        />

        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link rel="canonical" href="https://freshmentapp.com/explore-job" />
        <link
          rel="alternate"
          href="https://freshmentapp.com/explore-job"
          hrefLang="en-in"
        />

        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
        />
        <meta property="profile:username" content="freshment" />
        <meta
          itemProp="description"
          content="Apply for 5000 Jobs in India, Explore latest job openings in Rajkot, Ahmedabad, Mumbai, Pune, Bangalore, Delhi on Freshment - India’s No.1 Job Portal | Summer Internship | Remote Jobs | Freshers IT Jobs"
        />

        <meta
          property="og:title"
          content="Explore latest job openings in Mumbai, Pune, Bangalore, Delhi, Ahmedabad on Freshment"
        />

        <meta
          name="og:description"
          content="Apply for 5000 Jobs in India, Explore latest job openings in Rajkot, Ahmedabad, Mumbai, Pune, Bangalore, Delhi on Freshment - India’s No.1 Job Portal | Summer Internship | Remote Jobs | Freshers IT Jobs"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours"
        />
        <meta
          name="twitter:title"
          content="Explore latest job openings in Mumbai, Pune, Bangalore, Delhi, Ahmedabad on Freshment"
        />

        <meta
          name="twitter:description"
          content="Apply for 5000 Jobs in India, Explore latest job openings in Rajkot, Ahmedabad, Mumbai, Pune, Bangalore, Delhi on Freshment - India’s No.1 Job Portal | Summer Internship | Remote Jobs | Freshers IT Jobs"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta name="robots" content="index,follow" />

        <meta name="google" content="notranslate" />
        <meta http-equiv="Content-Language" content="en_US" />

        <meta
          name="keywords"
          content="best job search portal, fresherjobs, best job search app, internships, b tech fresher jobs, it jobs in pune, it jobs in mumbai, free internship, free job posting, resume writing, ai job portal, fresher jobs near me, qa fresher jobs near me, BDE jobs, sales jobs, marketing jobs, finance jobs, jobs near me, mca fresher jobs, mba fresher jobs, engineering fresher jobs"
        />
      </Helmet>
      {/* Header Starts */}
      <div
        style={{
          backgroundImage: `url(${jobDescHeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#F8F8FD",
        }}
        className="px-[124px] sm:px-[40px] pt-[88px] pb-[40px]"
      >
        <h1 className="text-[48px] font-[700] leading-[79px] sm:leading-[60px] sm:text-[40px] font-DmSans">
          Explore <span className="text-[#FF5F14] font-DmSans">Jobs</span>
        </h1>
        <img
          loading="lazy"
          src={exploreJobsUnderline}
          alt="bg-graphic"
          className="mb-[20px] ml-[172px] mt-[-10px] sm:hidden"
        />
        <p className="text-[20px] text-[#515B6F] mb-[40px]">
          Explore latest job openings in Rajkot, Ahmedabad, Mumbai, Pune,
          Bangalore, Delhi on Freshment, Summer Internship | Remote Jobs |
          Freshers Jobs
        </p>
        <div>
          <SearchBar
            searchString={search || ""}
            handleSearch={handleSearch}
            placeholder="Job title or keyword"
            subtitle="Popular : UI Designer, UX Researcher, Android, Admin"
          />
        </div>
      </div>
      {/* Header Ends */}
      <div className="bg-[#F8F8FD] px-[124px] sm:px-[40px] pb-[80px]">
        {
          <div className="flex mb-[76px] flex-wrap gap-y-[20px] sm:gap-y-[16px] gap-x-[40px] sm:gap-x-[20px]">
            {exploreJobTabs.map((item) => (
              <MenuItem
                key={item.id}
                item={item}
                onTabSelect={onTabSelect}
                selectedTab={selectedTab}
              />
            ))}
          </div>
        }

        {!govJobs?.length ? (
          <EmptyState className="min-h-[300px]" />
        ) : (
          <div className="flex gap-[32px]">
            <div className="grow-1 w-full">
              <div className="">
                <h4 className="text-[#25324B] text-[30px] font-[700] font-DmSans">
                  All Jobs
                </h4>
              </div>
              <p className="text-[#7C8493] font-[500] mb-[30px]">
                Showing {filteredItems?.totalRecords} results
              </p>
              <div className="hidden sm:justify-center sm:flex mt-[10px] mb-[10px] gap-[4px]">
                <MobileFilters
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                />
              </div>
              <div className="grid gap-[16px]">
                {filteredItems?.data?.map((job) => (
                  <AllJobs {...job} key={job.id} />
                ))}
              </div>

              <div className="mt-[34px]">
                <PaginationComponent
                  totalRecords={filteredItems?.totalRecords}
                  itemsPerPage={ITEMS_PER_PAGE}
                  handlePageChange={handlePageChange}
                  setPageTo={+page === 0 ? 1 : +page}
                ></PaginationComponent>
              </div>
            </div>

            <div className="min-w-[300px] sm:hidden">
              <div className="flex mt-[28px] mb-[48px] gap-[4px]">
                <img loading="lazy" src={filter} alt="" width={24} />
                <p className="text-[#707070] font-[600]">Filter</p>
              </div>
              {isFiltersLoaded && (
                <JobFilter
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ExploreJob;
