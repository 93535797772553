import axios from "axios";

const axiosClient = axios.create({
  baseURL: `https://govtjobsapi.governmentjobsforgraduates.com/api/web`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let res = error.response;
    if (res?.status == 401) {
      // window.location.href = https://example.com/login”;
    }
    console.error("Looks like there was a problem.Status Code: " + res?.status);
    return Promise.reject(error);
  }
);

// Function for GET request
export async function getData(endpoint: string, params?: any) {
  try {
    const response = await axiosClient.get(endpoint, {
      params: params,
      paramsSerializer: {
        indexes: null, // by default: false
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function for POST request
export const postData = async (endpoint: string, data: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const response = await axiosClient.post(endpoint, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
