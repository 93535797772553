import React, { useEffect, useState } from "react";
import {
  CurrenAffairs,
  EmptyState,
  JobFilter,
  MobileFilters,
  PaginationComponent,
  RecentAffairs,
} from "../../shared-components";
import filter from "../../assets/icons/filter.svg";
import {
  IFiltersGroup,
} from "../../shared-components/interfaces/current-affairs";
import {
  useCurrentAffairs,
  useFeaturedCurrentAffairs,
} from "../../network/APIs/current-affairs";
import { CURRENT_AFFAIRS_ITEMS_PER_PAGE } from "../../shared-components/constants/constants";
import { IHandlePageChangeEvent } from "../../shared-components/interfaces/common.interface";
import CurrentAffairsDetailsPage from "../../shared-components/current-affair-details";
import { getGKCategories } from "../../network/APIs/materials";
import { IFiltersOption } from "../../shared-components/interfaces/current-affairs";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function CurrentAffairs(props) {
  const ITEMS_PER_PAGE = CURRENT_AFFAIRS_ITEMS_PER_PAGE;

  const [searchParams, setSearchParams] = useSearchParams();

  const categoryId = searchParams.get("categoryId");
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const currentAffairId = searchParams.get("currentAffairId");

  const [query, setQuery] = useState<any>();
  const { data: filteredItems } = useCurrentAffairs(query);
  const { data: topCurrentAffairs } = useFeaturedCurrentAffairs();
  const [filters, setFilters] = useState<IFiltersGroup[]>([]);

  // Fetch current affairs, job categories
  useEffect(() => {
    getGKCategories().then((data) => {
      const newCat = data.data.categories.filter((cat) => cat.id !== "1");
      if (data.success) {
        const newCategory: IFiltersGroup = {
          label: "Category",
          type: "checkbox",
          value: "categoryId",
          filters: [],
        };
        newCat.forEach((category: IFiltersOption) => {
          newCategory.filters.push({
            id: Number(category.id),
            lable: category.name as string,
            value: category.id as string,
          });
        });
        setFilters((prev) => [newCategory, ...prev]);
      } else {
        setFilters((prev) => [...prev]);
      }
    });
  }, []);

  // On category query param change
  useEffect(() => {
    const newQuery = {
      categoryId: Array.isArray(categoryId) ? categoryId.join(",") : categoryId,
      search,
      offset:
        ((isNaN(Number(page)) || !Number(page) ? 1 : Number(page)) - 1) *
        ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    };

    setQuery(newQuery);
  }, [categoryId, search, page, ITEMS_PER_PAGE]);

  // Handle filter
  const handleFilterChange = (appliedFilters: IFiltersGroup[]) => {
    const queryParams: { [key: string]: any } = {};

    appliedFilters.forEach((filter_group) => {
      const groupValues: any = [];

      filter_group.filters.forEach((filter) => {
        if (filter.isChecked) {
          groupValues.push(filter.value);
        }
      });

      if (groupValues?.length) {
        queryParams[filter_group.value] =
          groupValues.length === 1 ? groupValues[0] : groupValues;
      }
    });

    searchParams.delete("categoryId");
    Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, queryParams[key]);
    });
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const emitSearchString = (search: string) => {
    searchParams.delete("page");

    if (search) {
      searchParams.set("search", search);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(search);
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = ({ page }: IHandlePageChangeEvent) => {
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  const navigateToDetailPage = (currentAffairId: number) => {
    const keys = searchParams.keys();
    for (const key of keys) {
      searchParams.delete(key);
    }
    searchParams.append("currentAffairId", currentAffairId + "");
    setSearchParams(searchParams);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <>
      {currentAffairId ? (
        <CurrentAffairsDetailsPage />
      ) : (
        <>
          <Helmet>
            <title>
              Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs
              PDF on Freshment
            </title>

            <meta
              name="title"
              content="Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs PDF on Freshment"
            />

            <meta
              name="description"
              content="Latest Current Affairs Today 2023 [#1 Site to Prepare Current Affairs] - Get Daily Current Affairs to prepare for upcoming Government exams. Stay updated with Weekly on freshmentapp.com"
            />

            <meta
              name="image"
              content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
            />

            <link
              rel="canonical"
              href="https://freshmentapp.com/current-affairs"
            />
            <link
              rel="alternate"
              href="https://freshmentapp.com/current-affairs"
              hrefLang="en-in"
            />

            <meta name="author" content="freshment" />
            <meta property="business:contact_data:locality" content="Rajkot" />
            <meta
              property="business:contact_data:postal_code"
              content="360002"
            />
            <meta
              property="business:contact_data:country_name"
              content="India"
            />
            <meta
              property="business:contact_data:email"
              content="contact@freshmentapp.com"
            />
            <meta
              property="business:contact_data:website"
              content="https://freshmentapp.com"
            />
            <meta
              itemProp="name"
              content="Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs PDF on Freshment"
            />
            <meta property="profile:username" content="freshment" />
            <meta
              itemProp="description"
              content="Latest Current Affairs Today 2023 [#1 Site to Prepare Current Affairs] - Get Daily Current Affairs to prepare for upcoming Government exams. Stay updated with Weekly on freshmentapp.com"
            />

            <meta
              property="og:title"
              content="Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs PDF on Freshment"
            />

            <meta
              name="og:description"
              content="Latest Current Affairs Today 2023 [#1 Site to Prepare Current Affairs] - Get Daily Current Affairs to prepare for upcoming Government exams. Stay updated with Weekly on freshmentapp.com"
            />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://freshmentapp.com" />
            <meta
              property="og:determiner"
              content="Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs PDF on Freshment"
            />
            <meta
              name="twitter:title"
              content="Today&#039;s Current Affairs 2023 - Daily, Monthly Current Affairs PDF on Freshment"
            />

            <meta
              name="twitter:description"
              content="Latest Current Affairs Today 2023 [#1 Site to Prepare Current Affairs] - Get Daily Current Affairs to prepare for upcoming Government exams. Stay updated with Weekly on freshmentapp.com"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="freshment" />
            <meta
              name="twitter:image"
              content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
            />
            <meta name="robots" content="index,follow" />

            <meta name="google" content="notranslate" />
            <meta http-equiv="Content-Language" content="en_US" />

            <meta
              name="keywords"
              content="Current Affairs 2023, daily Current Affairs 2023, latest Current Affairs, current affairs today, gk today, gk updates, monthly current affairs, quiz gk, general knowledge, gk today current affairs, gk studies, upsc 2023, gpsc 2023, general awareness"
            />
          </Helmet>
          <div className="bg-[#F8F8FD] px-[124px] sm:px-[40px] pb-[80px] pt-[40px]">
            <h4 className="text-[#25324B] text-[36px] sm:text-[30px] font-[700] mb-[20px] font-DmSans">
              Top Current Affairs
            </h4>
            <div className="gap-[46px] grid sm:block grid-cols-12 grid-rows-1">
              {topCurrentAffairs?.length ? (
                <div
                  className="col-span-7 sm:col-span-12 sm:mb-[20px]"
                  onClick={() =>
                    navigateToDetailPage(+topCurrentAffairs[0]?.id)
                  }
                >
                  <CurrenAffairs
                    {...(topCurrentAffairs?.length ? topCurrentAffairs[0] : undefined)}
                  />
                </div>
              ) : false}
              <div className="grid gap-[32px] col-span-5 sm:col-span-12 sm:block">
                {topCurrentAffairs?.slice(1, 4).map((recentAffair, index) => {
                  return (
                    <RecentAffairs
                      {...recentAffair}
                      key={recentAffair.id + "-" + index}
                    />
                  );
                })}
              </div>
            </div>
            <div className="h-[1px] w-full bg-[#DEDEDE] mt-[30px] mb-[50px]"></div>
            <div className="flex gap-[46px] justify-between">
              {!filteredItems?.data?.length ? (
                <EmptyState className="min-h-[300px]" />
              ) : (
                // Latest Current Affairs
                <div className="grow-1 w-full">
                  <div className="">
                    <h4 className="text-[#25324B] text-[36px] sm:text-[30px] font-[700] mb-[20px] font-DmSans">
                      Latest Current Affairs
                    </h4>
                    <div className="hidden sm:flex sm:justify-center mt-[28px] mb-[24px] gap-[4px]">
                      <MobileFilters
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        emitSearchString={emitSearchString}
                        hideTitle={true}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(380px, 1fr))",
                    }}
                    className="grid gap-[46px]"
                  >
                    {filteredItems?.data?.map((currentAffair, index) => (
                      <div
                        className="sm:w-[calc(100vw-80px)]"
                        onClick={() => navigateToDetailPage(+currentAffair.id)}
                      >
                        <CurrenAffairs
                          {...currentAffair}
                          key={currentAffair.id + "-" + index}
                        />
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  <div className="mt-[34px] sm:flex">
                    <PaginationComponent
                      totalRecords={filteredItems?.totalRecords}
                      itemsPerPage={ITEMS_PER_PAGE}
                      handlePageChange={handlePageChange}
                      setPageTo={+page === 0 ? 1 : +page}
                    ></PaginationComponent>
                  </div>
                </div>
              )}

              {/* Filter section */}
              <div className="w-[340px] min-w-[340px] sm:hidden">
                <div className="flex mt-[28px] mb-[20px] gap-[4px]">
                  <img loading="lazy" src={filter} alt="" width={24} />
                  <p className="text-[#707070] font-[600]">Filter</p>
                </div>
                <JobFilter
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  emitSearchString={emitSearchString}
                  hideTitle={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CurrentAffairs;
