import { CandidateComponent, Ebook } from "../../shared-components";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import officeImg from "../../assets/images/office_anim.webp";
import studyUnderline from "../../assets/icons/study-underline.svg";
import { getFirstLetterCapital } from "../../shared-components/constants/constants";
import { Link, useParams, useSearchParams } from "react-router-dom";
import rigthArrowBlue from "../../assets/icons/blue-right-arrow.svg";
import { useEffect, useState } from "react";
import {
  getCandidateListing,
  ICandidateAnalysisPayload,
  ICompanySubscriptionPayload,
  sendCandidateAnalysis,
  subscribeToMembership,
} from "../../network/APIs/candidate-listing";
import { postAnalysis } from "../../network/APIs/analysis";

function AppliedJobDetails() {
  const { job_id, companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const access_token = searchParams.get("access_token");

  const [companyData, setCompanyData] = useState<any>({});
  const [candidateList, setCandidateList] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    "SUCCESS" | "FAILURE"
  >();

  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>("");

  useEffect(() => {
    fetchCandidateListing();
  }, []);

  const userData = localStorage.getItem("Freshment_user_data");
  const userdetail = userData ? JSON.parse(userData) : null;

  const checkAndSendAnalysis = (props: {
    job_id: string;
    user_id: string;
    type: string;
    operation: string;
  }) => {
    // Retrieve data from localStorage instead of sessionStorage
    const localStorageData =
      JSON.parse(localStorage.getItem("analyse_data")) || [];

    const existingAnalysis = localStorageData.find(
      (item) => item.job_id === props.job_id
    );

    if (existingAnalysis) {
      // If the job exists and status is 1, don't call the API
      if (existingAnalysis.status === 1) {
        console.log("Analysis already done for this job.");
        return;
      }
    } else {
      // If the job doesn't exist in localStorage, add it with status 1 and call the API
      const newAnalysis = {
        job_id: props.job_id,
        user_id: props.user_id,
        type: props.type,
        operation: props.operation, // Default status
      };
      localStorageData.push(newAnalysis);
      // Store the updated data in localStorage
      localStorage.setItem("analyse_data", JSON.stringify(localStorageData));
      postAnalysis(newAnalysis);
    }
  };

  const fetchCandidateListing = () => {
    const candidateListPayload = {
      job_id,
      company_id: companyId,
      access_token,
    };
    getCandidateListing(candidateListPayload).then((response) => {
      if (response.success) {
        const mappedKeys = response.data?.map((obj) => {
          obj.document_title = obj.name;
          obj.id = obj.user_id;
          obj.document_name = obj.email;
          obj.file_url = obj.resume;
          return obj;
        });
        setCandidateList(mappedKeys);

        const compData = response.company_data;
        if (mappedKeys?.length) {
          compData.job_title = mappedKeys[0].job_title;
          compData.job_type = mappedKeys[0].job_type;
          compData.job_id = mappedKeys[0].job_id;
        }
        setCompanyData(compData);
      } else {
        setError(true);
        setErrorMsg(response.message);
      }
    });
  };

  const candidateAnalysis = (candidate, action) => {
    const candidateListPayload: ICandidateAnalysisPayload = {
      operation: "add",
      company_id: companyData?.company_id,
      job_id: candidate?.job_id,
      user_id: candidate?.user_id,
      type: action,
    };
    sendCandidateAnalysis(candidateListPayload).then((response) => {});
  };

  const subscribeMembership = () => {
    const candidateListPayload: ICompanySubscriptionPayload = {
      company_id: companyData?.company_id,
    };
    subscribeToMembership(candidateListPayload).then((response) => {
      if (response.success) {
        setSubscriptionStatus("SUCCESS");
      } else {
        setSubscriptionStatus("FAILURE");
      }
    });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${jobDescHeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#F8F8FD",
        }}
        className="px-[124px] pt-[88px] pb-[40px] sm:px-[40px] height-[340px] sm:height-[290px]"
      >
        <h1 className="text-[48px] sm:text-[40px] font-[700] leading-[79px] sm:leading-[60px] font-DmSans">
          Received Job{" "}
          <span className="text-[#FF5F14] font-DmSans">Applications</span> on
          Freshment
        </h1>
        <p className="text-[20px] sm:text-[16px] text-[#515B6F] mb-[40px]">
          Hire Best Candidates, Post Your Job Free, Get Access to Resumes FREE,
          Contact candidates with phone calls
        </p>
      </div>

      {/* Candidate listing */}
      {!isError && (
        <div className="bg-[#F8F8FD] px-[124px] pb-[80px] sm:px-[4vw]">
          <div className="bg-white p-[20px] mb-[32px] rounded-[10px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)]">
            <div className="flex justify-between items-center">
              <h5 className="text-[20px] sm:text-[16px] font-[600] mb-[10px] mr-[10px]">
                {getFirstLetterCapital(companyData?.job_title || "-")}
              </h5>
              <Link
                to={`/job-description/${companyData.job_id}`}
                onClick={() => {
                  checkAndSendAnalysis({
                    job_id: companyData?.job_id,
                    user_id: userdetail?.data?.id ?? null,
                    type: "like",
                    operation: "add",
                  });
                }}
                className="text-[#2670FF] text-[16px] sm:text-[14px] flex gap-[16px] sm:gap-[12px] min-w-[93px]"
              >
                View job{" "}
                <img
                  loading="lazy"
                  alt="right arrow"
                  src={rigthArrowBlue}
                  height="24"
                  width="24"
                />
              </Link>
            </div>
            <div className="flex sm:block text-[20px] sm:text-[16px]">
              <div className="font-[500] text-[#515B6F] sm:text-[16px]">
                {getFirstLetterCapital(companyData?.job_type || "-")}
              </div>
              <div className="h-[28px] w-[1.5px] bg-[#515B6F] ml-[30px] mr-[20px] sm:hidden"></div>
              <div className="font-[500] text-[#515B6F] sm:text-[16px]">
                {companyData?.company_name}
              </div>
              {/* {salary && (
              <>
                <div className="h-[28px] w-[1.5px] bg-[#515B6F] ml-[30px] mr-[20px] sm:hidden"></div>
                <div className="font-[500] text-[#515B6F]">
                  {salary || "-"} LPA
                </div>
              </>
            )} */}
            </div>
          </div>
          <div
            className="grid gap-[32px]"
            style={{
              gridTemplateColumns:
                "repeat(auto-fit, minmax(max(370px, 33%), 1fr))",
            }}
          >
            {candidateList.map((candidate) => (
              <CandidateComponent
                {...candidate}
                key={candidate.id}
                mat_id={1}
                isCandidateUI={true}
                candidate={candidate}
                candidateAnalysis={candidateAnalysis}
              />
            ))}
          </div>
        </div>
      )}

      {/* Subscribe premium section */}
      {!isError ? (
        <div className="flex justify-around mt-[72px] sm:flex-col-reverse sm:mt-[60px] sm:p-[20px] mx-[124px] sm:mx-[40px] h-[414px] sm:h-fit sm:items-center	sm:overflow-hidden bg-[#f6fbff] rounded-[10px] sm:rounded-[40px]">
          <div className="ml-[8%] flex-[3] align self-center sm:flex sm:flex-col sm:ml-0 sm:text-center sm:items-center">
            <h4 className="text-[#414b5d] text-[28px] sm:text-[40px] mb-[24px] font-DmSans font-[700]">
              Search Candidates from India's Fastest Growing Talent Pool
            </h4>
            <p className="text-[#414b5d] text-[16px] mb-[24px] font-[500]">
              Subscribe to our Premium Services, We will reach out to you. Find
              candidates from Freshment - India's Fastest Growing Talent Pool
              and find the perfect talent for your organisation, With Features
              Unlimited Job Posting, Recommended Candidate Search, Unlimited
              Profile Access and Resumes Download.
            </p>
            <button
              onClick={() => subscribeMembership()}
              className="bg-[#2670FF] w-fit px-[24px] py-[12px] mb-[10px] text-[#FFFFFF] font-[700]"
            >
              {subscriptionStatus === "SUCCESS" ? "Thank You" : "Subscribe"}
            </button>
            {subscriptionStatus === "SUCCESS" && (
              <p className="text-[#414b5d] text-[16px] mb-[24px] font-[400]">
                Our team will get back to you soon!
              </p>
            )}
          </div>

          <div className="flex-[2] flex items-center justify-center">
            <img
              loading="lazy"
              src={officeImg}
              alt="mobile icon"
              height="330"
              width="308"
              className="sm:mt-[70px] mix-blend-multiply"
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-between flex-col items-center mt-[84px]">
          {/* <h3 className="text-[24px] font-[700] mb-[20px] font-DmSans text-[#25324B]">
            {errorMessage}
          </h3> */}
          <p
            className="mb-[44px] font-DmSans font-[500] px-[14vw] text-center text-[#515B6F]"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          ></p>
          <Link to={"/"}>
            <button className="py-[12px] px-[24px] mb-[122px] font-epilogue rounded-[10px] bg-[#2670FF] text-white font-[700]">
              Continue to Homepage
            </button>
          </Link>
        </div>
      )}
    </>
  );
}

export default AppliedJobDetails;
