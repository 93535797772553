import { getData } from '../api-client';
import { FEED, JOB_CATEGORIES_HOME, GOVT_JOBS_HOME, FRESHERS_JOBS_HOME, LATEST_JOBS_HOME, GOVT_JOBS_CATEGORIES, JOB_CATEGORIES, FRESHERS_JOBS_CATEGORIES, EXPLORE_JOBS_CATEGORIES } from './../../shared/constants/routing-constants';
import { useQuery } from '@tanstack/react-query';

export function useJobCategoriesHome() {
  return useQuery({queryKey: ['jobCategoriesHome'], queryFn: () => getData(`${FEED}/${JOB_CATEGORIES_HOME}`).then(res => res.data.categories)})
}

export function useGovtJobsHome() {
  return useQuery({ queryKey: ['govtJobsHome'], queryFn: () => getData(`${FEED}/${GOVT_JOBS_HOME}`).then(res => res.data) })
}

export function useFreshersJobsHome() {
  return useQuery({ queryKey: ['freshersJobsHome'], queryFn: () => getData(`${FEED}/${FRESHERS_JOBS_HOME}`).then(res => res.data) })
}

export function useLatestJobsHome() {
  return useQuery({ queryKey: ['latestJobsHome'], queryFn: () => getData(`${FEED}/${LATEST_JOBS_HOME}`).then(res => res.data) })
}


export function getGovtJobsCategories() {
  return getData(`${FEED}/${GOVT_JOBS_CATEGORIES}`);
}

export function getFreshJobsCategories() {
  return getData(`${FEED}/${FRESHERS_JOBS_CATEGORIES}`);
}

export function getExploreJobsCategories() {
  return getData(`${FEED}/${EXPLORE_JOBS_CATEGORIES}`);
}