import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";

import "./index.css";
// import "./output.css";
import App from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-E58Y1WGR4J");

ReactDOM.render(
  <React.StrictMode>
    {/* <HashRouter> */}
    {/* The rest of your app goes here */}
    {/* <App /> */}

    {/* </HashRouter> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
