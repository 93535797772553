import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, Link, Page } from "@mui/material";
import footer1 from "../../assets/images/about-img-1.webp";
import footer2 from "../../assets/images/about-img-2.webp";
import footer3 from "../../assets/images/about-img-3.webp";
import aboutOffice1 from "../../assets/images/about-office-1.webp";
import aboutOffice2 from "../../assets/images/about-office-2.webp";
// import { WOW } from "wowjs";
import './about.css'

const isServer = typeof window === "undefined";
const WOW = !isServer ? require("wow.js") : null;

const AboutView = () => {
  useEffect(() => {
    const wow = new WOW({ live: false }); // disables sync requirement
    wow.init();
  }, []);

  return (
    <div >
      <Container maxWidth="lg">
        <Box className="about-container wow fadeInUp">
          <Box display="flex" justifyContent="center">
            <Typography variant="h1">
              Freshment - Strengthening the Youth Employment
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography className="sub-head-text wow fadeInUp">
              With our startup "Freshment," we've made a small step forward in terms of connecting to the national agenda and the state's aim of boosting and strengthening the Employment and Placement Ecosystem. Furthermore, Get the job you deserve with Freshment - the AI-powered jobs & company platform. Our technology sorts and filters through thousands of jobs in just a few seconds, giving you a personalised dashboard tailored to your education and experience level. No more worrying about outdated job postings or long hours spent searching through job boards - Freshment has your back!
            </Typography>

            <blockquote className="sub-head-text quote wow fadeInUp">
              Freshment is India's leading Job Portal - Search & Apply for Latest Job Vacancies across Top Companies in India, Employers can Post Job & Hire Candidates for Free
            </blockquote>
          </Box>

          <Box className="top-imgs">
            <Grid container spacing={3} className="img-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                classname="padding-remove wow slideInLeft"
              >
                <img loading="lazy" src={aboutOffice1} className="htop-1" />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="padding-remove wow slideInRight"
              >
                <img loading="lazy" src={aboutOffice2} className="htop-2" />
              </Grid>
            </Grid>
          </Box>

          {/* EMPOWE SECTION */}
          <Box className="empower-section">
            <Grid container spacing={3} className="empower-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className=" wow slideInLeft"
              >
                <Typography variant="h4">
                  Search Local Job Seekers Easily
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className=" wow slideInRight"
              >
                <Typography className="desc">
                  Get ready to advance your career with Freshment! Our innovative job platform helps freshers, students and young professionals to find the right opportunities faster and more effectively. With an easy-to-use interface, 1000+ active jobs, 1000+ Training and Placement Officers connected across India, and 50,000+ Job Seekers already linked to our platform, Freshment is the leading destination for job seekers. Don't wait - join our growing community today and take the first step towards your dream career!
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Discover, measure, and hire the best talent with Freshment! Our innovative solutions let you engage students and professionals in the most interactive way to fulfil your objectives like employer branding, hiring, and employee engagement. From posting jobs to highlighting your job on social media and selecting candidates across the complete HR lifecycle, Freshment is your go-to tool for efficient recruitment. Get started today!
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  We know how that feels, we have been there, and that's why
                  we’ve come up with a solution. offshoring.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className="empower-section">
            <Grid container spacing={3} className="empower-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className=" wow slideInLeft"
              >
                <Typography variant="h4" className="recue-title">
                  Freshment is here to Boost Employment
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className=" wow slideInRight"
              >
                <Typography className="desc">
                  Find the perfect job for you with Freshment! Our AI-powered platform quickly and accurately sorts jobs based on your education, creating a personalised dashboard that ensures you never miss an opportunity. Discover jobs in CS/IT, Mech/Civil, EC/Elec, Engg and MBA easily - it's the smarter way to find your dream job!
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  With Freshment, finding the perfect job for freshers has never been easier! Our powerful platform connects you with thousands of job opportunities from top employers across India. Plus, our network of Training & Placement Officers helps you land the perfect position for your career. Get ready for a new job, with Freshment! Try it now and get connected to 1000+ active jobs today.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Want to know about the perfect job for you? Freshment app has you covered! Get tailored job alerts based on your preferences and get notified of new openings, government jobs, internships, study materials, GK updates & more. Our platform is fast, secure and mobile-friendly - so you can find your dream job in no time! Save time & money by avoiding the hassle of looking for jobs.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Download Freshment App Today from Play Store and join thousands of happy users who rely on Freshment to help them find their perfect job
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {/* <Box className="bluebg">
        <Container>
          <Grid container className="download-info">
            <Grid
              item
              className="download-app wow fadeInLeft"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Typography className="download-head">
                Download the app now & use it.
              </Typography>
              <Box className="store-links">
                <Link target="_blank" href="#">
                  <AppStoreIcon className="app-store" />
                </Link>
                <Link target="_blank" href="#">
                  <PlayStoreIcon className="play-store" />
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              className="homesec wow fadeInUp"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
               <Avatar
                src={Download}
                variant="square"
                className="download-img"
              /> 
            </Grid>
          </Grid>
        </Container>
      </Box> */}
      <Container maxWidth="lg">
        <Box className="about-container wow fadeInUp">
          {/* Freshment in the News */}
          <Box className="team-section">
            <Box className="wow fadeInUp">
              <Typography variant="h3">Freshment in the News</Typography>
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" className="desc">
                  For this initiative, a variety of print and digital media outlets have published our tales to encourage other women to embark on their entrepreneurial journeys.
                </Typography>
              </Box>

              <Grid container spacing={4} className="team-container ">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInLeft"
                >
                  <img loading="lazy" src={footer1} />
                  {/* <Typography className="name">David Martin</Typography>
                  <Typography className="position">Founder & CTO</Typography> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow fadeInUpwow fadeInUp "
                >
                  <img loading="lazy" src={footer2} />
                  {/* <Typography className="name">David Martin</Typography>
                  <Typography className="position">
                    Creative Director
                  </Typography> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInRight"
                >
                  <img loading="lazy" src={footer3} />
                  {/* <Typography className="name">David Martin</Typography>
                  <Typography className="position">Design Head</Typography> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* <Container maxWidth="lg">
        <Box className="about-container wow fadeInUp">
          {/* MEET OUR TEAM *
          <Box className="team-section">
            <Box className="wow fadeInUp">
              <Typography variant="h3">Meet our team</Typography>
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" className="desc">
                  Leverage agile frameworks to provide a robust synopsis for
                  high level overviews. Iterative approaches to corporate
                  strategy.
                </Typography>
              </Box>

              <Grid container spacing={4} className="team-container ">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInLeft"
                >
                  {/* <Avatar variant="square" src={Team1Img} /> *
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">Founder & CTO</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow fadeInUpwow fadeInUp "
                >
                  {/* <Avatar variant="square" src={Team2Img} /> *
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">
                    Creative Director
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInRight"
                >
                  {/* <Avatar variant="square" src={Team3Img} /> *
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">Design Head</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container> */}
    </div>
  );
};

export default AboutView;
