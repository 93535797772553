import {
  FEED,
  GET_GK_ARCHIVES,
  GET_GK_CATEGORIES,
  GET_PLACEMENT_COMPANIES,
  GET_PLACEMENT_PAPERS,
  GET_STUDY_MATERIAL_WEB,
  WEB_USER,
  GET_TESTIMONALS,
} from "../../shared/constants/routing-constants";
import { getData } from "../api-client";

export function getStudyMaterial(
  materialType: number,
  queryParam?: { [key: string]: any }
) {
  return getData(`${FEED}/${GET_STUDY_MATERIAL_WEB}`, {
    materialType,
    languageType: 1,
    offset: 0,
    limit: 10,
    ...queryParam,
  });
}

export function getPlacementPapers(queryParam?: { [key: string]: any }) {
  return getData(`${FEED}/${GET_PLACEMENT_PAPERS}`, { ...queryParam });
}

export function getPlacementCompanies() {
  return getData(`${FEED}/${GET_PLACEMENT_COMPANIES}`);
}

export function getGKResources(
  categoryid: number,
  queryParam?: { [key: string]: any }
) {
  return getData(`${FEED}/${GET_GK_ARCHIVES}`, { categoryid, ...queryParam });
}

export function getGKCategories() {
  return getData(`${FEED}/${GET_GK_CATEGORIES}`);
}

export function getTestimonals() {
  return getData(`${WEB_USER}/${GET_TESTIMONALS}`);
}
