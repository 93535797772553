import React, { useEffect, useState } from "react";
import { studyMaterialMenu } from "../constants/constants";
import {
  Ebook,
  EmptyState,
  GovExamPapers,
  MenuItem,
  PaginationComponent,
  PlacementPapers,
  SearchBar,
} from "../../shared-components";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import {
  getGKCategories,
  getGKResources,
  getPlacementCompanies,
  getPlacementPapers,
  getStudyMaterial,
} from "../../network/APIs/materials";
import { IHandlePageChangeEvent } from "../../shared-components/interfaces/common.interface";
import { Chip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import studyUnderline from "../../assets/icons/study-underline.svg";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useWindowSize } from "../../shared/utils";

function StudyMaterial() {
  const ITEMS_PER_PAGE = 10;
  const [selectedTab, setSelectedTab] = useState<number>();
  const [listItems, setListItems] = useState<any[]>([]);
  const [filteredListItems, setFilteredListItems] = useState<any[]>([]);
  const [subListItems, setSubListItems] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedChips, setSelectedChips] = useState<
    | {
        id: number;
        img_url: string;
      }
    | any
  >({});

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const categoryid = searchParams.get("categoryid");
  const [screenWidth, screenHeight] = useWindowSize();

  useEffect(() => {
    setSelectedTab(1);
  }, []);

  useEffect(() => {
    const newQuery = {
      search,
      offset:
        ((isNaN(Number(page)) || !Number(page) ? 1 : Number(page)) - 1) *
        ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      categoryid: Number(categoryid) || undefined,
    };

    if (!search) {
      delete newQuery.search;
    }

    switch (selectedTab) {
      case 1:
      case 2:
      case 3:
        getStudyMaterial(selectedTab, newQuery).then((data) => {
          if (data.success) {
            setTotalRecords(data.totalRecords);
            setListItems(data.materials);
          } else {
            setListItems([]);
            setTotalRecords(0);
          }
        });
        break;

      case 4:
        getPlacementCompanies().then((data) => {
          if (data.success) {
            setSubListItems(data.data.categories);
            if (!categoryid) {
              setSelectedChips({
                id: Number(data.data.categories[0].id),
                img_url: data.data.categories[0].image_url,
              });
            }
          } else {
            setListItems([]);
          }
        });

        if (newQuery.categoryid) {
          getPlacementPapers(newQuery).then((data) => {
            if (data.success) {
              setTotalRecords(data.data.length);
              setListItems(data.data);
              setFilteredListItems(
                data.data.slice(
                  (+(page || 1) - 1) * ITEMS_PER_PAGE,
                  (+(page || 1) - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
              );
            } else {
              setListItems([]);
              setFilteredListItems([]);
              setTotalRecords(0);
            }
          });
        } else {
          setListItems([]);
          setTotalRecords(0);
        }
        break;
      case 5:
        getGKCategories().then((data) => {
          const newCat = data.data.categories.filter((cat) => cat.id !== "1");
          if (data.success) {
            setSubListItems(newCat);
            if (!categoryid) {
              setSelectedChips({
                id: Number(newCat[0].id),
                img_url: newCat[0].image_url,
              });
            }
          } else {
            setListItems([]);
          }
        });
        getGKResources(2, newQuery).then((data) => {
          if (data.success) {
            setTotalRecords(data.data.length);
            setListItems(data.data);
            setFilteredListItems(
              data.data.slice(
                (+(page || 1) - 1) * ITEMS_PER_PAGE,
                (+(page || 1) - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
              )
            );
          } else {
            setListItems([]);
            setFilteredListItems([]);
            setTotalRecords(0);
          }
        });
        break;

      default:
        break;
    }
  }, [selectedTab, search, page, categoryid]);

  useEffect(() => {
    deleteAllQueryParamsFromRoute();
  }, [selectedTab]);

  const onTabSelect = (tabId: number) => {
    searchParams.delete(categoryid);
    setSearchParams(searchParams);
    setListItems([]);
    setTotalRecords(0);
    setSelectedTab(tabId);
  };

  const handleChipClick = (chip: any) => {
    setSelectedChips({
      id: Number(chip.id),
      img_url: chip.image_url,
    });
  };

  useEffect(() => {
    searchParams.set("categoryid", selectedChips.id);
    setSearchParams(searchParams);
  }, [selectedChips]);

  const handlePageChange = ({ page }: IHandlePageChangeEvent) => {
    if (selectedTab === 4 || selectedTab === 5) {
      setFilteredListItems(
        listItems.slice(
          (page - 1) * ITEMS_PER_PAGE,
          (page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        )
      );
    } else {
      setFilteredListItems([]);
    }

    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  const deleteAllQueryParamsFromRoute = () => {
    const keys = searchParams.keys();
    for (const key of keys) {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };

  const renderTabItems = (listItem: any) => {
    switch (selectedTab) {
      case 1:
        return <Ebook {...listItem} key={listItem.id} mat_id={selectedTab} />;

      case 2:
      case 3:
        return (
          <GovExamPapers {...listItem} key={listItem.id} mat_id={selectedTab} />
        );

      case 4:
      case 5:
        return (
          <PlacementPapers
            {...listItem}
            default_img={selectedChips.img_url}
            key={listItem.id}
            componentName={selectedTab === 5 ? "GK" : "PlacementPaper"}
            downloadBtnName={
              selectedTab === 5 && +categoryid === 19 ? "Read" : null
            }
            mat_id={selectedTab}
          />
        );
    }
  };

  const renderDynamicCardWidth = () => {

    const isSmallScreen =
      screenWidth < 1023 ? "sm:" : "";

    switch (selectedTab) {
      case 2:
      case 3:
        return "grid-cols-[repeat(auto-fill,minmax(274px,1fr))]";

      default:
        return isSmallScreen
          ? isSmallScreen+"grid-cols-[repeat(auto-fill,minmax(calc(100vw - 80px),1fr))]"
          : "grid-cols-[repeat(auto-fill,minmax(572px,1fr))]";
    }
  };

  const handleSearch = (search: string) => {
    if (search) {
      searchParams.set("search", search + "");
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Free Government Job Examination Study Material and Question Papers,
          Competitive Exam Books 2023
        </title>
        <meta
          name="description"
          content="Free Online Government Job Study Material, Previous Year Question Papers, Competitive Exam books and Mock Test available with us for Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE"
        />

        <meta
          name="title"
          content="Free Government Job Examination Study Material and Question Papers, Competitive Exam Books 2023"
        />

        <meta
          name="description"
          content="Free Online Government Job Study Material, Previous Year Question Papers, Competitive Exam books and Mock Test available with us for Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE"
        />

        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link rel="canonical" href="https://freshmentapp.com/study-material" />
        <link
          rel="alternate"
          href="https://freshmentapp.com/study-material"
          hrefLang="en-in"
        />

        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
        />
        <meta property="profile:username" content="freshment" />

        <meta
          itemProp="description"
          content="Free Online Government Job Study Material, Previous Year Question Papers, Competitive Exam books and Mock Test available with us for Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE"
        />

        <meta
          property="og:title"
          content="Free Government Job Examination Study Material and Question Papers, Competitive Exam Books 2023"
        />

        <meta
          name="og:description"
          content="Free Online Government Job Study Material, Previous Year Question Papers, Competitive Exam books and Mock Test available with us for Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Free Government Job Examination Study Material and Question Papers, Competitive Exam Books 2023"
        />
        <meta
          name="twitter:title"
          content="Free Government Job Examination Study Material and Question Papers, Competitive Exam Books 2023"
        />

        <meta
          name="twitter:description"
          content="Free Online Government Job Study Material, Previous Year Question Papers, Competitive Exam books and Mock Test available with us for Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta name="robots" content="index,follow" />

        <meta name="google" content="notranslate" />
        <meta http-equiv="Content-Language" content="en_US" />

        <meta
          name="keywords"
          content="Free Online Government Job Study Material, Previous Year Question Papers and Mock Test available on various Government Recruitment Web Sites Sarkari Naukri Govt. Jobs 2020, Sarkari Naukri Government Govt Jobs in India 2023, Bank exam books, competitive exam books, Government job preparation books, books for SSC, Books for Railways, Books for IBPS, UPSC Books, SSC books, GATE BOOKS, CUET BOOK"
        />
      </Helmet>

      {/* Header Starts */}
      <div
        style={{
          backgroundImage: `url(${jobDescHeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#F8F8FD",
        }}
        className="px-[124px] sm:px-[40px] pt-[88px] pb-[40px]"
      >
        <h1 className="text-[48px] sm:text-[40px] font-[700] leading-[79px] sm:leading-[60px] font-DmSans">
          Study <span className="text-[#FF5F14] font-DmSans">Materials</span>
        </h1>
        <img
loading="lazy"            src={studyUnderline}
          alt="bg-graphic"
          className="mb-[20px] ml-[130px] mt-[-10px] sm:hidden"
        />
        <p className="text-[20px] sm:text-[16px] text-[#515B6F] mb-[40px]">
          Free Online Government Job Study Material, Previous Year Question
          Papers, Competitive Exam books and Mock Test available with us for
          Government Exam Preparation for IBPS, SBI, SSC CGL, UPSC, GATE
        </p>
        <div>
          <SearchBar
            handleSearch={handleSearch}
            placeholder="Material name or keyword"
            subtitle="Popular : GPSC Material, CHSL Exam, English Grammer"
          />
        </div>
      </div>
      {/* Header Ends */}

      <div className="bg-[#F8F8FD] px-[124px] sm:px-[40px] pb-[80px]">
        {
          <>
            <div className="mb-[25px] flex flex-wrap gap-y-[20px] gap-x-[40px] sm:gap-x-[20px] sm:flex-nowrap	 sm:flex sm:overflow-x-auto sm:pb-[16px]">
              {studyMaterialMenu.map((item) => (
                <MenuItem
                  key={item.id}
                  item={item}
                  onTabSelect={onTabSelect}
                  selectedTab={selectedTab ? selectedTab : 0}
                />
              ))}
            </div>
            {selectedTab === 4 || selectedTab === 5 ? (
              <div className="overflow-x-auto mb-[25px] pb-[16px] flex gap-y-[20px] gap-x-[40px] sm:gap-x-[20px]">
                {subListItems.map((chip) => (
                  <Chip
                    key={chip.id}
                    avatar={<Avatar alt="logo" src={chip.image_url} />}
                    label={chip.name}
                    variant="outlined"
                    sx={{
                      fontSize: 14,
                      color:
                        selectedChips.id === Number(chip.id)
                          ? "white"
                          : "black",
                      backgroundColor:
                        selectedChips.id === Number(chip.id)
                          ? "#FF5F14"
                          : "white",
                      borderColor:
                        selectedChips.id === Number(chip.id)
                          ? "#FF5F14"
                          : undefined,
                      padding: "20px 12px",
                      "&&:hover": {
                        color:
                          selectedChips.id === Number(chip.id)
                            ? "white"
                            : undefined,
                        backgroundColor:
                          selectedChips.id === Number(chip.id)
                            ? "#FF5F14"
                            : "white",
                        borderColor: "#FF5F14",
                      },
                    }}
                    onClick={() => handleChipClick(chip)}
                  />
                ))}
              </div>
            ) : null}
          </>
        }
        {!listItems?.length ? (
          <EmptyState className="min-h-[300px]" />
        ) : (
          <>
            <div className={`grid gap-[32px] ${renderDynamicCardWidth()}`}>
              {(selectedTab === 4 || selectedTab === 5
                ? filteredListItems
                : listItems
              ).map((listItem) => {
                return renderTabItems(listItem);
              })}
            </div>

            <div className="mt-[34px]">
              <PaginationComponent
                totalRecords={totalRecords}
                itemsPerPage={ITEMS_PER_PAGE}
                handlePageChange={handlePageChange}
              ></PaginationComponent>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default StudyMaterial;
