import React, { useEffect, useState } from "react";
import ThirdStep from "./thirdStep";
import firstGrey from "../../assets/icons/firstGrey.svg";
import secondGrey from "../../assets/icons/secondGrey.svg";
import thirdGrey from "../../assets/icons/thirdGrey.svg";
import firstOrange from "../../assets/icons/firstOrange.svg";
import secondOrange from "../../assets/icons/secondOrange.svg";
import thirdOrange from "../../assets/icons/thirdOrange.svg";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import { FormProvider, useForm } from "react-hook-form";
import FourthStep from "./fourthStep";
import selectArrow from "../../assets/icons/selectArrow.svg";
import studyUnderline from "../../assets/icons/study-underline.svg";
import { Link } from "react-router-dom";
import scrollToTop from "../../shared/utils";
import { getFirstLetterCapital } from "../../shared-components/constants/constants";
import "./../../index";
import { Helmet } from "react-helmet";
import { getCities, postYourJob } from "../../network/APIs/post-your-job";

function PostYourJob() {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [step, setStep] = useState(1);
  const [cities, setCities] = useState<{ id: number; city: string }[]>([]);
  const [filteredCities, setFilteredCities] = useState<
    { id: number; city: string }[]
  >([]);
  const [selectedCity, setSelectedCity] = useState<number>();

  useEffect(() => {
    reset();
    getUserCities();
  }, []);

  const city = watch("city");

  useEffect(() => {
    const inputCity: string = city;
    const filtered = cities.filter((city) =>
      city?.city?.toLowerCase().includes(inputCity?.toLowerCase())
    );
    setFilteredCities(filtered);
  }, [city, cities]);

  const getUserCities = () => {
    getCities().then((cities) => setCities(cities.data));
  };

  const handleNextButton = async () => {
    // check if any errors in current page
    let isValid = false;

    switch (step) {
      case 1:
        isValid = await trigger([
          "company_name",
          "company_email",
          "job_role",
          "city",
        ]);
        break;

      case 2:
        isValid = await trigger([
          "job_requirements",
          "job_required_skills",
          "educational_qualification",
          "experience",
        ]);
        break;

      default:
        break;
    }

    if (isValid) {
      setStep(step + 1);
    }
    scrollToTop();
  };

  const handleBackButton = () => {
    setStep(step - 1);
    scrollToTop();
  };

  const onSubmit = (data: any) => {
    data.city_id = selectedCity;
    if (!data.vacancy) {
      data.vacancy = 1;
    }
    const emptyParamsCheck = [
      "salary",
      "area",
      "contact_no",
      "complete_address",
    ];
    emptyParamsCheck.forEach((param) => {
      if (!data[param]) {
        data[param] = "";
      }
    });

    data.job_required_skills = data.job_required_skills.replace(
      /(?:\r\n|\r|\n)/g,
      "<br>"
    );

    data.job_requirements = data.job_requirements.replace(
      /(?:\r\n|\r|\n)/g,
      "<br>"
    );

    postYourJob(data)
      .then((response) => {
        if (response?.success) {
          setStep(4);
        } else {
          alert("Error occurred");
        }
      })
      .catch(() => {
        alert("Error occurred");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Helmet>
          <title>
            Post Job Requirements for Free &amp; Hire Staff - Freshment
          </title>
          <meta
            name="description"
            content="Post Job Requirements for free in Mumbai, Delhi, Bangalore &amp; Pune on Freshment, India&#x27;s top online job posting site for employers. Access 10 lakh + candidate resumes &amp; hire staff"
          />
          <meta
            name="title"
            content="Post Job Requirements for Free &amp; Hire Staff - Freshment"
          />
          <meta
            name="image"
            content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
          />
          <link rel="canonical" href="https://freshmentapp.com/post-your-job" />
          <link
            rel="alternate"
            href="https://freshmentapp.com/post-your-job"
            hrefLang="en-in"
          />

          <meta name="author" content="freshment" />
          <meta property="business:contact_data:locality" content="Rajkot" />
          <meta property="business:contact_data:postal_code" content="360002" />
          <meta property="business:contact_data:country_name" content="India" />
          <meta
            property="business:contact_data:email"
            content="contact@freshmentapp.com"
          />
          <meta
            property="business:contact_data:website"
            content="https://freshmentapp.com"
          />
          <meta
            itemProp="name"
            content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
          />
          <meta property="profile:username" content="freshment" />

          <meta
            itemProp="description"
            content="Post Job Requirements for free in Mumbai, Delhi, Bangalore &amp; Pune on Freshment, India&#x27;s top online job posting site for employers. Access 10 lakh + candidate resumes &amp; hire staff"
          />

          <meta
            property="og:title"
            content="Post Job Requirements for Free &amp; Hire Staff - Freshment"
          />

          <meta
            name="og:description"
            content="Post Job Requirements for free in Mumbai, Delhi, Bangalore &amp; Pune on Freshment, India&#x27;s top online job posting site for employers. Access 10 lakh + candidate resumes &amp; hire staff"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://freshmentapp.com" />
          <meta
            property="og:determiner"
            content="Post Job Requirements for Free &amp; Hire Staff - Freshment"
          />
          <meta
            name="twitter:title"
            content="Post Job Requirements for Free &amp; Hire Staff - Freshment"
          />

          <meta
            name="twitter:description"
            content="Post Job Requirements for free in Mumbai, Delhi, Bangalore &amp; Pune on Freshment, India&#x27;s top online job posting site for employers. Access 10 lakh + candidate resumes &amp; hire staff"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="freshment" />
          <meta
            name="twitter:image"
            content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
          />
          <meta name="robots" content="index,follow" />

          <meta name="google" content="notranslate" />
          <meta http-equiv="Content-Language" content="en_US" />

          <meta
            name="keywords"
            content="post job requirements, post job free, hire candidates free, hire top candidates, campus placement, campus hiring, post job requirement free, where can i post a job posting, how to post job hiring, how to post job requirement in naukri, requirements to post a job on indeed, hire candidates for job, hire candidates for free, hire candidates for sales and marketing, hiring appropriate candidates, how to hire candidates for free, hire fresher candidates, i want to hire candidates, "
          />
        </Helmet>

        {/* Header Starts */}
        {step !== 4 ? (
          <div
            style={{
              backgroundImage: `url(${jobDescHeaderBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#F8F8FD",
            }}
            className="px-[124px] sm:px-[40px] pt-[88px] pb-[40px]"
          >
            <h1 className="text-[48px] sm:text-[40px] font-[700] leading-[79px] sm:leading-[60px] font-DmSans">
              Post your{" "}
              <span className="text-[#FF5F14] font-DmSans">First Job</span>
            </h1>
            <img
              loading="lazy"
              src={studyUnderline}
              alt="bg-graphic"
              className="mb-[20px] ml-[200px] mt-[-10px] sm:hidden"
            />
            <p className="text-[20px] sm:text-[16px] text-[#515B6F] mb-[40px]">
              Tell Us About Your Company to Get Started
            </p>
          </div>
        ) : null}
        {/* Header Ends */}

        <div className="px-[124px] sm:px-[40px]">
          {step !== 4 ? (
            <div className="flex items-center sm:flex-wrap justify-between gap-[40px] sm:gap-[20px] mb-[80px] sm:mb-[60px]  mt-[40px]">
              <div className="flex min-w-fit">
                <img
                  loading="lazy"
                  src={step === 1 ? firstOrange : firstGrey}
                  alt="1"
                  width={40}
                  height={40}
                  className="mr-[10px]"
                />
                <h4
                  className={`text-[20px] leading-[40px] font-[500] ${
                    step === 1 ? "text-[#FF5F14]" : "text-[#707070]"
                  }`}
                >
                  Basic Details
                </h4>
              </div>
              <div className="bg-[#1E1E1E] h-[1px] w-full sm:hidden"></div>
              <div className="flex min-w-fit">
                <img
                  loading="lazy"
                  src={step === 2 ? secondOrange : secondGrey}
                  alt="1"
                  width={40}
                  height={40}
                  className="mr-[10px]"
                />
                <h4
                  className={`text-[20px] leading-[40px] font-[500] ${
                    step === 2 ? "text-[#FF5F14]" : "text-[#707070]"
                  }`}
                >
                  Candidate Requirements
                </h4>
              </div>
              <div className="bg-[#1E1E1E] h-[1px] w-full sm:hidden"></div>
              <div className="flex min-w-fit">
                <img
                  loading="lazy"
                  src={step === 3 ? thirdOrange : thirdGrey}
                  alt="1"
                  width={40}
                  height={40}
                  className="mr-[10px]"
                />
                <h4
                  className={`text-[20px] leading-[40px] font-[500] ${
                    step === 3 ? "text-[#FF5F14]" : "text-[#707070]"
                  }`}
                >
                  Preview
                </h4>
              </div>
            </div>
          ) : null}

          {/* <div>{formStep()}</div> */}

          {step === 1 ? (
            <div>
              {
                <>
                  <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
                    Job Details
                  </h4>
                  <div className="grid grid-cols-2 sm:grid-cols-1 gap-y-[20px] gap-x-[71px] mb-[50px]">
                    <div className="flex flex-col">
                      <label
                        htmlFor="company_name"
                        className="font-[600] text-[#7C8493]"
                      >
                        Company Name
                        <span className="text-rose-600 font-bold"> *</span>
                      </label>
                      <input
                        {...register("company_name", {
                          required: true,
                        })}
                        autoFocus
                        type="text"
                        id="company_name"
                        placeholder="Enter Company Name"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {errors.company_name && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )}
                    </div>
                    <div></div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="job_role"
                        className="font-[600] text-[#7C8493]"
                      >
                        Job Role
                        <span className="text-rose-600 font-bold"> *</span>
                      </label>
                      <input
                        {...register("job_role", {
                          required: true,
                        })}
                        type="text"
                        id="job_role"
                        name="job_role"
                        placeholder="Enter Job Role"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {errors.job_role && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="vacancy"
                        className="font-[600] text-[#7C8493]"
                      >
                        No. of Opening
                      </label>
                      <input
                        {...register("vacancy")}
                        type="number"
                        id="vacancy"
                        name="vacancy"
                        placeholder="No. of openings"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {errors.vacancy && (
                        <p className="text-rose-600">
                          Value must be greater than zero
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="job_type"
                        className="font-[600] text-[#7C8493]"
                      >
                        Type of Job
                        <span className="text-rose-600 font-bold"> *</span>
                      </label>
                      <select
                        {...register("job_type", {
                          value: "FULL TIME",
                        })}
                        style={{
                          backgroundImage: `url(${selectArrow})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 1rem center",
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                        id="job_type"
                        name="job_type"
                        defaultValue="DEFAULT"
                        className="appearance-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      >
                        {/* <option disabled hidden>
                      Select Job Type
                    </option> */}
                        <option value="FULL TIME">Full Time</option>
                        <option value="PART TIME">Part Time</option>
                        <option value="REMOTE">Work From Home</option>
                      </select>
                      {/* {errors.job_type && (
                    <p className="text-rose-600">This is a required field</p>
                  )} */}
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="salary"
                        className="font-[600] text-[#7C8493]"
                      >
                        Salary <small>(in LPA)</small>
                      </label>
                      <input
                        {...register("salary", {
                          validate: (value) => {
                            if (
                              value !== null &&
                              value !== undefined &&
                              value !== ""
                            ) {
                              if ((value + "").length >= 5) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          },
                          // required: true,
                          // min: 0,
                        })}
                        type="number"
                        id="salary"
                        name="salary"
                        placeholder="Ex. 360000"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {errors?.salary?.type === "validate" && (
                        <p className="text-rose-600">
                          Salary should be minimum 5 digits
                        </p>
                      )}
                    </div>
                  </div>
                  <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
                    Job Location
                  </h4>
                  <div className="grid grid-cols-2 sm:grid-cols-1 gap-y-[20px] gap-x-[71px] mb-[50px]">
                    <div className="flex flex-col relative sm:col-span-2">
                      <label
                        htmlFor="city"
                        className="font-[600] text-[#7C8493]"
                      >
                        Job City
                        <span className="text-rose-600 font-bold"> *</span>
                      </label>
                      <input
                        {...register("city", {
                          required: true,
                        })}
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Enter Job City"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                        onKeyDown={() => setSelectedCity(null)}
                      />
                      {filteredCities?.length ? (
                        <ul className="absolute top-0 rounded-sm overflow-auto	left-0 mt-[95px] w-full max-h-[200px] bg-white">
                          {filteredCities.map((city) => {
                            return (
                              <li
                                key={city.id}
                                className="py-[10px] px-[4px] text-[14px] hover:bg-[#f0f0f0]"
                                onClick={() => {
                                  setValue("city", city.city, {
                                    shouldValidate: true,
                                  });
                                  setSelectedCity(city.id);
                                }}
                              >
                                {city.city}
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                      {errors.city && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col sm:col-span-2">
                      <label
                        htmlFor="area"
                        className="font-[600] text-[#7C8493]"
                      >
                        Area
                      </label>
                      <input
                        {...register("area", {
                          // required: true,
                        })}
                        type="text"
                        id="area"
                        name="area"
                        placeholder="Enter Job Area"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {/* {errors.area && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )} */}
                    </div>
                    <div className="flex flex-col col-span-2">
                      <label
                        htmlFor="complete_address"
                        className="font-[600] text-[#7C8493]"
                      >
                        Complete Address
                      </label>
                      <input
                        {...register("complete_address", {
                          // required: true,
                        })}
                        type="text"
                        id="complete_address"
                        name="complete_address"
                        placeholder="Enter Full Address"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {/* {errors.complete_address && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )} */}
                    </div>
                  </div>
                  <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
                    Company Contact Details
                  </h4>
                  <div className="grid grid-cols-2 sm:grid-cols-1 gap-y-[20px] gap-x-[71px] mb-[70px]">
                    <div className="flex flex-col sm:col-span-2">
                      <label
                        htmlFor="contact_no"
                        className="font-[600] text-[#7C8493]"
                      >
                        Phone Number
                      </label>
                      <input
                        {...register("contact_no", {
                          // required: true,
                        })}
                        type="text"
                        id="contact_no"
                        name="contact_no"
                        placeholder="Enter Phone Number"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {/* {errors?.contact_no?.type === "required" && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )} */}
                    </div>
                    <div className="flex flex-col sm:col-span-2">
                      <label
                        htmlFor="company_email"
                        className="font-[600] text-[#7C8493]"
                      >
                        HR Email
                        <span className="text-rose-600 font-bold"> *</span>
                      </label>
                      <input
                        {...register("company_email", {
                          required: true,
                        })}
                        type="email"
                        id="company_email"
                        name="company_email"
                        placeholder="Enter HR Email"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {errors.company_email && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col col-span-2">
                      <label
                        htmlFor="company_website"
                        className="font-[600] text-[#7C8493]"
                      >
                        Website URL
                      </label>
                      <input
                        {...register("company_website", {
                          // required: true,
                        })}
                        type="text"
                        id="company_website"
                        name="company_website"
                        placeholder="Enter Website URL"
                        className="border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                      />
                      {/* {errors.company_website && (
                        <p className="text-rose-600">
                          This is a required field
                        </p>
                      )} */}
                    </div>
                  </div>
                </>
              }
            </div>
          ) : step === 2 ? (
            <div>
              <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
                Job Description
              </h4>
              <div className="grid grid-rows-2 grid-cols-1 gap-[30px] mb-[50px]">
                {/* <div className="flex flex-col">
                  <label
                    htmlFor="job_responsibilities"
                    className="font-[600] text-[#7C8493]"
                  >
                    Roles And Responsibilities
                  </label>
                  <textarea
                    {...register("job_responsibilities", {
                      required: true,
                    })}
                    name="job_responsibilities"
                    id="job_responsibilities"
                    placeholder="Enter Roles And Responsibilities"
                    className="h-[100px] resize-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                  ></textarea>
                  {errors.job_responsibilities && (
                    <p className="text-rose-600">This is a required field</p>
                  )}
                </div> */}
                <div className="flex flex-col">
                  <label
                    htmlFor="job_requirements"
                    className="font-[600] text-[#7C8493]"
                  >
                    Requirements
                    <span className="text-rose-600 font-bold"> *</span>
                  </label>
                  <textarea
                    {...register("job_requirements", {
                      required: true,
                      minLength: 200,
                    })}
                    autoFocus
                    name="job_requirements"
                    id="job_requirements"
                    placeholder="Enter Requirements"
                    className="h-[200px] resize-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                  ></textarea>
                  {errors?.job_requirements &&
                    errors?.job_requirements?.type === "required" && (
                      <p className="text-rose-600">This is a required field</p>
                    )}

                  {errors?.job_requirements &&
                    errors?.job_requirements?.type === "minLength" && (
                      <p className="text-rose-600">
                        Please enter minimum 200 characters
                      </p>
                    )}
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="job_required_skills"
                    className="font-[600] text-[#7C8493]"
                  >
                    Required Skills
                    <span className="text-rose-600 font-bold"> *</span>
                  </label>
                  <textarea
                    {...register("job_required_skills", {
                      required: true,
                      minLength: 100,
                    })}
                    name="job_required_skills"
                    id="job_required_skills"
                    placeholder="Enter Required Skills"
                    className="h-[200px] resize-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                  ></textarea>
                  {errors?.job_required_skills &&
                    errors?.job_required_skills?.type === "required" && (
                      <p className="text-rose-600">This is a required field</p>
                    )}

                  {errors?.job_required_skills &&
                    errors?.job_required_skills?.type === "minLength" && (
                      <p className="text-rose-600">
                        Please enter minimum 100 characters
                      </p>
                    )}
                </div>
              </div>
              <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
                Requirement Details
              </h4>
              <div className="grid grid-rows-1 grid-cols-1 gap-[30px] mb-[70px]">
                <div className="flex flex-col">
                  <label
                    htmlFor="educational_qualification"
                    className="font-[600] text-[#7C8493]"
                  >
                    Educational Qualification
                    <span className="text-rose-600 font-bold"> *</span>
                  </label>
                  <select
                    style={{
                      backgroundImage: `url(${selectArrow})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 1rem center",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    {...register("educational_qualification", {
                      required: true,
                      value: "",
                    })}
                    id="educational_qualification"
                    name="educational_qualification"
                    className="appearance-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                  >
                    <option value="" disabled hidden>
                      Select Educational Qualification
                    </option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                    <option value="SSC">SSC</option>
                    <option value="ITI">ITI</option>
                    <option value="HSC">HSC</option>
                    <option value="DIPLOMA">DIPLOMA</option>
                    <option value="ICWA/CA">ICWA/CA</option>
                    <option value="DOCTORS">DOCTORS</option>
                    <option value="PHD">PHD</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                  {errors.educational_qualification && (
                    <p className="text-rose-600">This is a required field</p>
                  )}
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="experience"
                    className="font-[600] text-[#7C8493]"
                  >
                    Experience Required
                    <span className="text-rose-600 font-bold"> *</span>
                  </label>
                  <select
                    style={{
                      backgroundImage: `url(${selectArrow})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 1rem center",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    {...register("experience", {
                      required: true,
                      value: "",
                    })}
                    id="experience"
                    name="experience"
                    className="appearance-none border border-[#7C8493] rounded-[10px] p-[20px] outline-none"
                  >
                    <option value="" disabled hidden>
                      Select Experience Level
                    </option>
                    <option value="0">Fresher</option>
                    <option value="1">Below 2 Years</option>
                    <option value="2">2-5 Years</option>
                    <option value="3">5-7 Year</option>
                    <option value="4">Greater Than 7</option>
                  </select>
                  {errors.experience && (
                    <p className="text-rose-600">This is a required field</p>
                  )}
                </div>

                {/* <div className="flex flex-col">
          <label
            htmlFor="experience"
            className="font-[600] text-[#7C8493] mb-[20px]"
          >
            Experience Required
          </label>
          <div className="flex gap-[44px]">
            <div className="flex items-center">
              <input
                type="radio"
                id="any"
                name="experience"
                value="ANY"
                className="h-[24px] w-[24px] mr-[14px] cursor-pointer"
              />
              <label htmlFor="any" className="font-[500] text-[#7C8493]">
                Any
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="fresher"
                name="experience"
                value="FRESHER"
                className="h-[24px] w-[24px] mr-[14px] cursor-pointer"
              />
              <label htmlFor="fresher" className="font-[500] text-[#7C8493]">
                Fresher Only
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="experienced"
                name="experience"
                value="EXPERIENCED"
                className="h-[24px] w-[24px] mr-[14px] cursor-pointer"
              />
              <label
                htmlFor="experienced"
                className="font-[500] text-[#7C8493]"
              >
                Experienced Only
              </label>
            </div>
          </div>
        </div> */}
              </div>
            </div>
          ) : step === 3 ? (
            <div>
              <div>
                <h4 className="text-[24px] font-[700] mb-[20px] font-DmSans">
                  Job Role Preview
                </h4>
                <div className="p-[20px] rounded-[10px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] mb-[50px]">
                  <h5 className="text-[20px] font-[600] mb-[10px]">
                    {getFirstLetterCapital(getValues().job_role)}
                  </h5>
                  <div className="flex sm:block">
                    <span className="text-[20px] font-[500] text-[#515B6F] sm:block">
                      {getFirstLetterCapital(getValues().job_type)}
                    </span>
                    <div className="h-[28px] w-[1.5px] bg-[#515B6F] ml-[30px] mr-[20px] sm:hidden"></div>
                    <span className="text-[20px] font-[500] text-[#515B6F] sm:block">
                      {getValues().company_name},{" "}
                      {getFirstLetterCapital(getValues().city)}
                    </span>
                    {getValues().salary && (
                      <>
                        <div className="h-[28px] w-[1.5px] bg-[#515B6F] ml-[30px] mr-[20px] sm:hidden"></div>
                        <span className="text-[20px] font-[500] text-[#515B6F] sm:block">
                          {getValues().salary || "-"} LPA
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-[20px]">
                <h4 className="text-[24px] font-[700] mb-[20px] font-DmSans">
                  Job Description
                </h4>
                <div className="p-[20px] rounded-[10px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] mb-[50px]">
                  <div className="flex sm:block">
                    <span className="text-[20px] font-[500] text-[#515B6F] sm:block">
                      {getValues()?.job_requirements}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-[20px]">
                <h4 className="text-[24px] font-[700] mb-[20px] font-DmSans">
                  Required Skills
                </h4>
                <div className="p-[20px] rounded-[10px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] mb-[50px]">
                  <div className="flex sm:block">
                    <span className="text-[20px] font-[500] text-[#515B6F] sm:block">
                      {getValues()?.job_required_skills}
                    </span>
                  </div>
                </div>
              </div>

              {/* <h4 className="text-[24px] font-[700] mb-[30px] font-DmSans">
        Select Your Plan
      </h4>
       <div className="flex justify-center gap-[50px] mb-[80px]">
        <div className="w-[370px] p-[20px] rounded-[10px] shadow-[1px_2px_6px_rgba(0,0,0,0.25)]">
          <div className="flex justify-between mt-[20px]">
            <div className="ml-[20px]">
              <h5 className="text-[24px] font-[600px] text-[#25324B]">Basic</h5>
              <p className="font-[600px] text-[#515B6F]">Most Popular</p>
            </div>
            <div className="w-[127px] mr-[10px] text-center rounded-[10px] bg-[#F4F8FF]">
              <h5 className="text-[20px] font-[600px] text-[#25324B]">Free</h5>
              <p className="font-[600px] text-[#515B6F]">Per Post</p>
            </div>
          </div>
          <div className="h-[1px] bg-[#D8D8D8] mt-[30px] mb-[20px]"></div>
          <div className="flex mx-[10px] mb-[10px]">
            <img
loading="lazy"                src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Job Expiry: 15 Day</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Push Notification</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={crossmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#C2C2C2] font-[500]">Job Highlighted </p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={crossmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#C2C2C2] font-[500]">Resume Access</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={crossmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#C2C2C2] font-[500]">
              Email Campaign To Target Audience
            </p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={crossmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#C2C2C2] font-[500]">Social Media Promotion</p>
          </div>
          <button
            type="button"
            className="bg-[#FF5F14] mt-[10px] h-[54px] w-full font-[700] rounded-[10px] text-white"
          >
            Select
          </button>
        </div>
        <div className="w-[370px] p-[20px] rounded-[10px] shadow-[1px_2px_6px_rgba(0,0,0,0.25)]">
          <div className="flex justify-between mt-[20px]">
            <div className="ml-[20px]">
              <h5 className="text-[24px] font-[600px] text-[#25324B]">
                Professional
              </h5>
              <p className="font-[600px] text-[#515B6F]">Recommended</p>
            </div>
            <div className="w-[127px] mr-[10px] text-center rounded-[10px] bg-[#F4F8FF]">
              <h5 className="text-[20px] font-[600px] text-[#25324B]">₹ 500</h5>
              <p className="font-[600px] text-[#515B6F]">Per Post</p>
            </div>
          </div>
          <div className="h-[1px] bg-[#D8D8D8] mt-[30px] mb-[20px]"></div>
          <div className="flex mx-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Job Expiry: 15 Day</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Push Notification</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Job Highlighted </p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Resume Access</p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">
              Email Campaign To Target Audience
            </p>
          </div>
          <div className="flex ml-[10px] mb-[10px]">
            <Image
              src={checkmark}
              alt="included"
              width={20}
              height={20}
              className="mr-[10px]"
            />
            <p className="text-[#25324B] font-[500]">Social Media Promotion</p>
          </div>
          <button
            type="button"
            className="bg-[#FF5F14] mt-[10px] h-[54px] w-full font-[700] rounded-[10px] text-white"
          >
            Select
          </button>
        </div>
      </div> */}
            </div>
          ) : step === 4 ? (
            <div className="flex justify-between flex-col items-center mt-[84px]">
              <h3 className="text-[24px] font-[700] mb-[20px] font-DmSans text-[#25324B]">
                Thank you for choosing us!
              </h3>
              <p className="mb-[44px] font-DmSans font-[500] text-[#515B6F]">
                You’ll receive your email for further instructions.
              </p>
              <Link to={"/"}>
                <button className="py-[12px] px-[24px] mb-[122px] font-epilogue rounded-[10px] bg-[#2670FF] text-white font-[700]">
                  Continue to Homepage
                </button>
              </Link>
            </div>
          ) : null}

          {step !== 4 ? (
            <div className="flex gap-[20px] mb-[100px] pb-[80px]">
              {/* {step === 1 ? (
              <button className="py-[10px] w-[244px] box-border border border-[#2670FF] rounded-[10px] text-[#2670FF] text-[18px] font-[700]">
                Cancel
              </button>
            ) : null} */}
              {step >= 2 ? (
                <button
                  type="button"
                  className="py-[10px] w-[244px] box-border border border-[#2670FF] rounded-[10px] text-[#2670FF] text-[18px] font-[700]"
                  onClick={handleBackButton}
                >
                  Back
                </button>
              ) : null}
              {step <= 2 ? (
                <button
                  type="button"
                  className="bg-[#2670FF] py-[14px] w-[244px] text-[18px] font-[700] rounded-[10px] text-white"
                  onClick={handleNextButton}
                >
                  Next
                </button>
              ) : null}
              {step === 3 ? (
                <button
                  type="submit"
                  className="bg-[#2670FF] py-[14px] w-[244px] text-[18px] font-[700] rounded-[10px] text-white"
                >
                  Post Your Job
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default PostYourJob;
