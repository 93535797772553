// APIS

// General
export const FEED = "feed";
export const COMPANY = "company";

// Categories
export const JOB_CATEGORIES_HOME = "get_job_categories_home";
export const JOB_CATEGORIES = "jobcategories";
export const GOVT_JOBS_HOME = `get_govt_job_list_home?limit=8&offset=0`;
export const FRESHERS_JOBS_HOME = `get_fresher_job_list_home?limit=8&offset=0`;
export const LATEST_JOBS_HOME = `get_latest_job_list_home?limit=8&offset=0`;
export const GOVT_JOBS_CATEGORIES = `jobcategories?govt=1`;
export const FRESHERS_JOBS_CATEGORIES = `jobcategories?govt=0`;
export const EXPLORE_JOBS_CATEGORIES = `jobcategories?govt=2`;

// Current Affairs
export const CURRENT_AFFAIRS = "get_current_affairs_web";
export const FEATURED_CURRENT_AFFAIRS = "get_featured_current_affairs";
export const CURRENT_AFFAIRS_DETAILS = "get_current_affairs_details";

// Jobs
export const GOVT_JOBS_LIST = "get_govt_job_list";
export const FRESHERS_JOBS_LIST = "get_private_job_list";
export const JOB_DETAILS = "get_job_details_web";
export const EXPLORE_JOB = "get_jobs_by_qualification";

// Material
export const GET_STUDY_MATERIAL_WEB = "get_study_materials_web";
export const GET_PLACEMENT_PAPERS = "get_placement_papers";
export const GET_PLACEMENT_COMPANIES = "getPlacementCompanies";
export const GET_GK_ARCHIVES = "get_GK_archives";
export const GET_GK_CATEGORIES = "getGKCategories_v2";

// Feedback
export const ADD_FEEDBACK = "add_feedback";
export const CONNECT_CAMPUS = "connect_campus";

// Post your job
export const POST_YOUR_JOB = "post_your_job";
export const GET_USER_LOCATIONS = "getUserLocations";

// Subscribe news letter
export const SUBSCRIBE_NOW = "subscribe_now";

// Candidates
export const GET_CANDIDATE_LISTING = "getCandidateListing";
export const ADD_CANDIDATE_ANALYSIS = "add_candidate_analysis";
export const SUBSCRIBE_PREMIUM_MEMBERSHIP = "subscribe_premium_membership";

// APP ROUTING
export const CURRENT_AFFAIRS_APP = "current-affairs";
export const JOB_DETAILS_APP = "job-description";
export const EXPLORE_JOB_APP = "explore-job";

// ANALYSIS
export const WEB_USER = "WebUser";
export const ADD_JOB_ANALYSIS = "add_job_analysis";

// LOGIN
export const USER = "user";
export const LOGIN = "login";

// SIGNUP
export const SIGNUP = "signup";

// TESTIMONAL
export const GET_TESTIMONALS = "get_testimonials";
