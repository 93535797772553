import { EXPLORE_JOB, JOB_DETAILS } from './../../shared/constants/routing-constants';
import { FEED, GOVT_JOBS_LIST, FRESHERS_JOBS_LIST } from '../../shared/constants/routing-constants';
import { getData } from "../api-client";
import { useQuery } from '@tanstack/react-query';
import { gaEvents } from '../../shared-components/google-analytics-events/ga-events';


export function useGovtJobsList(queryParam?: { [key: string]: any }) {
  return useQuery({
    queryKey: ['govtJobsList', queryParam],
    queryFn: () => getData(`${FEED}/${GOVT_JOBS_LIST}`, { offset: 0, limit: 10, ...queryParam }).then(res => res)
  });
}

export function useFreshersJobsList(queryParam?: { [key: string]: any }) {
  return useQuery({
    queryKey: ['freshersJobsList', queryParam],
    queryFn: () => getData(`${FEED}/${FRESHERS_JOBS_LIST}`, { offset: 0, limit: 10, ...queryParam }).then(res => res)
  });
}

export function useJobDetails(job_id: number | string) {
  return useQuery({
    queryKey: ['jobDetails', job_id],
    queryFn: () => getData(`${FEED}/${JOB_DETAILS}`, { job_id, user_id: 22 }).then(res => res?.data),
    onSuccess: (data) => {
      gaEvents.viewJobEvent(
        data.details.id,
        data.details.job_category_id
      );
    }
  });
}

export function useExploreJobs(queryParam?: { [key: string]: any }) {
  return useQuery({
    queryKey: ['exploreJobs', queryParam],
    queryFn: () => getData(`${FEED}/${EXPLORE_JOB}`, { offset: 0, limit: 10, ...queryParam }).then(res => res)
  });
}